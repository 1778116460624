import React from 'react';
import Header from './Header';
import './TnC.css';

export default function TnC() {
  return (
    <>
      <Header />
      <div className="terms-container1">
        <h1>HerdMate Terms and Conditions</h1>
        <p className="last-updated1"><strong>Last Updated: November 15th, 2024</strong></p>

        <p>
          Welcome to HerdMate, an online platform designed to assist farmers in finding, purchasing, 
          and listing bull semen and cow embryos. By accessing or using the HerdMate website or mobile 
          application ("Service"), you agree to comply with and be bound by these Terms and Conditions ("Terms"). 
          Please read these Terms carefully. If you do not agree with any part of these Terms, you must not access 
          or use the Service.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing and using HerdMate, you affirm that you are at least 18 years old and capable of forming a 
          legally binding contract. If you are accessing the Service on behalf of a business or other legal entity, 
          you represent that you have the authority to bind that entity to these Terms.
        </p>

        <h2>2. Changes to Terms</h2>
        <p>
          HerdMate reserves the right to modify these Terms at any time. We will notify you of any significant 
          changes by posting the revised Terms on our website or by sending you an email notification. Continued use 
          of the Service after changes to these Terms will constitute your acceptance of those changes.
        </p>

        <h2>3. Registration and Account Security</h2>
        <p>
          To use certain features of the Service, you must register for an account. You agree to provide accurate, 
          current, and complete information during registration and to update your information to keep it accurate and complete. 
          You are responsible for safeguarding your account credentials and for any activity that occurs under your account. 
          HerdMate is not liable for any loss or damage arising from your failure to maintain the security of your account.
        </p>

        <h2>4. Use of the Service</h2>
        <ul>
          <li>Use the Service only for lawful purposes and in a manner consistent with these Terms.</li>
          <li>Do not use the Service for unlawful purposes or to solicit unlawful acts.</li>
          <li>Do not violate any international, federal, state, or local regulations.</li>
          <li>Do not upload or transmit viruses or malicious code.</li>
          <li>Do not harvest or collect information about other users without consent.</li>
          <li>Do not engage in conduct that restricts or inhibits other users from enjoying the Service.</li>
        </ul>

        <h2>5. Content and Intellectual Property</h2>
        <p>
          All content on the Service, including text, graphics, logos, images, and software, is the property of HerdMate 
          or its licensors and is protected by intellectual property laws. Reproduction or distribution without permission 
          is prohibited.
        </p>

        <h2>6. User-Generated Content</h2>
        <p>
          By submitting User Content, you grant HerdMate a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable 
          license to use, reproduce, modify, and distribute such content. You represent that your content does not violate 
          any third-party rights.
        </p>

        <h2>7. Listings and Transactions</h2>
        <p>
          HerdMate acts as a venue for listing, selling, and purchasing bull semen and cow embryos. Sellers and buyers 
          are responsible for ensuring compliance with applicable laws.
        </p>

        <h2>8. Fees and Payments</h2>
        <p>
          Fees may apply for certain services. Payments are processed through third-party payment processors. HerdMate is 
          not responsible for payment issues arising from these processors.
        </p>

        <h2>9. Refunds and Cancellations</h2>
        <p>
          Disputes regarding transactions must be resolved between buyers and sellers. HerdMate may mediate disputes but 
          is not obligated to act as a party.
        </p>

        <h2>10. Shipping and Delivery</h2>
        <p>
          HerdMate is not responsible for shipping or delivery. Sellers and buyers must coordinate these responsibilities.
        </p>

        <h2>11. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, HerdMate will not be liable for damages arising from the use of the Service. 
          Total liability is limited to the amount paid to HerdMate in the past six months.
        </p>

        <h2>12. Disclaimer of Warranties</h2>
        <p>
          HerdMate provides the Service "as is" and "as available." We do not guarantee uninterrupted or error-free operation.
        </p>

        <h2>13. Indemnification</h2>
        <p>
          You agree to indemnify and hold HerdMate harmless from claims or damages resulting from your use of the Service.
        </p>

        <h2>14. Dispute Resolution and Arbitration</h2>
        <p>
          Disputes will be resolved through binding arbitration under the rules of the American Arbitration Association.
        </p>

        <h2>15. Governing Law</h2>
        <p>
          These Terms are governed by the laws of [Your State]. Disputes must be resolved in courts of [Your State].
        </p>

        <h2>16. Termination</h2>
        <p>
          HerdMate reserves the right to suspend or terminate your account for violating these Terms.
        </p>

        <h2>17. Privacy Policy</h2>
        <p>
          Use of the Service is subject to our Privacy Policy, which is incorporated into these Terms by reference.
        </p>

        <h2>18. Third-Party Services</h2>
        <p>
          HerdMate is not responsible for third-party content, products, or services accessed through the platform.
        </p>

        <h2>19. Force Majeure</h2>
        <p>
          HerdMate is not liable for failure to perform due to causes beyond its control.
        </p>

        <h2>20. No Waiver</h2>
        <p>
          HerdMate's failure to enforce a right does not waive that right.
        </p>

        <h2>21. Severability</h2>
        <p>
          If any provision of these Terms is found to be invalid, the remaining provisions remain enforceable.
        </p>

        <h2>22. Assignment</h2>
        <p>
          These Terms may not be assigned by you without HerdMate's consent.
        </p>

        <h2>23. Entire Agreement</h2>
        <p>
          These Terms constitute the entire agreement between you and HerdMate.
        </p>

        <h2>24. Contact Information</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <address>
          HerdMate<br />
          Ames, Iowa, 50014<br />
          <a href="mailto:team@herdmate.com">team@herdmate.app</a>
        </address>
      </div>
    </>
  );
}
