import React, { useState } from 'react';
import './Login.css';
import Header from './Header';
import supabase from './supabaseClient'; // Import Supabase client

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Clear any existing errors

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        setError(error.message); // Display error message if login fails
      } else if (data && data.user) {
        // Store user ID in localStorage
        localStorage.setItem('user_uuid', data.user.id);
        console.log("Logged in successfully:", data);
        
        // Redirect to home page or another protected route
        window.location.href = '/marketplace'; // Redirect after successful login
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="login1-page-container">
      <Header />
      <div className="login1-form-container">
        <h2>Sign in</h2>
        <form onSubmit={handleLogin} className="login1-form">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="login-button">Login</button>
          {error && <p className="error-message">{error}</p>} {/* Display error message */}
        </form>
        <div className="login1-footer">
          <a href="/signup">Sign up!</a>
          <a href="/forgot">Forgot Password?</a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
