import React, { useState } from 'react';
import './Signup.css';
import Header from './Header';
import supabase from './supabaseClient';
import AWS from 'aws-sdk';
import { awsConfig } from './awsConfig';

const usStates = [
  { value: '', label: '-- Select State --' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [farmName, setFarmName] = useState('');
  const [farmWebsite, setFarmWebsite] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const fileInputRef = React.createRef();

  // Configure AWS S3
  const s3 = new AWS.S3({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
    region: awsConfig.region,
    ACL: 'public-read', // Allow public access to the file
  });

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!agreeToTerms) {
      alert("You must agree to the Terms and Conditions to sign up.");
      return;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    if (!profilePicture) {
      alert("Please upload a profile picture.");
      return;
    }

    if (!name.includes(' ') || name.trim().split(' ').length < 2) {
      alert("Please enter your full name with a space separating first and last name.");
      return;
    }

    try {
      setIsSubmitting(true); // Disable the button once signup is initiated

      // Sign up the user in Supabase
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        console.error("Error signing up:", error.message);
        setIsSubmitting(false);
        return;
      }

      // Ensure we have a user object before proceeding
      const userId = data?.user?.id;
      if (!userId) {
        console.error("User ID not available after signup.");
        setIsSubmitting(false);
        return;
      }

      let imageUrl = '';
      if (profilePicture) {
        imageUrl = await uploadImageToS3(profilePicture, userId);
      }

      // Insert user data into the Supabase 'users' table
      const { error: insertError } = await supabase
        .from('users')
        .insert([
          {
            user_uuid: userId,
            name,
            email,
            phone_number: phone,
            address,
            city,
            state,
            zipcode,
            image: imageUrl,
            farm_name: farmName || null,
            farm_website: farmWebsite || null,
          },
        ]);

      if (insertError) {
        console.error("Error inserting user data:", insertError.message);
        alert("Error creating user profile: " + insertError.message);
        setIsSubmitting(false);
        return;
      }

      // Store user ID in localStorage
      localStorage.setItem('user_uuid', userId);

      window.location.href = '/signupemailconfirm'; // Redirect after successful signup
    } catch (err) {
      console.error("Unexpected error:", err);
      setIsSubmitting(false);
    }
  };

  const uploadImageToS3 = (file, userId) => {
    return new Promise((resolve, reject) => {
      setUploading(true);
      const params = {
        Bucket: awsConfig.bucketName,
        Key: `profile_pictures/${userId}_${file.name}`,
        Body: file,
        ContentType: file.type,
      };

      s3.upload(params, (err, data) => {
        setUploading(false);
        if (err) {
          console.error("Error uploading image:", err);
          reject(err);
        } else {
          console.log("Image uploaded successfully:", data.Location);
          resolve(data.Location); // Return the public URL of the image
        }
      });
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  return (
    <div className="signup-page-container">
      <Header />
      <div className="signup-form-container">
        <h2>Signup</h2>
        <div className="profile-image-wrapper1">
          <img
            src={
              profilePicture
                ? URL.createObjectURL(profilePicture)
                : 'https://placehold.co/600x400'
            }
            alt="Profile"
            className="profile-image1"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <button onClick={triggerFileInput} className="upload-button3">
            {uploading ? 'Uploading...' : 'Upload Profile Picture'}
          </button>
        </div>
        <form onSubmit={handleSignup} className="signup-form">
          <div className="signup-details-container">
            {/* Personal Details Section */}
            <div className="personal-details-section">
              <h3>Personal Details</h3>
              <label>First & Last Name*</label>
              <input
                type="text"
                placeholder="Enter your first & last name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label>Email*</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label>Phone*</label>
              <input
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <label>Address*</label>
              <input
                type="text"
                placeholder="Enter your address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
              <label>Password*</label>
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "🙈" : "👁️"}
                </button>
              </div>

              <label>Confirm Password*</label>
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "🙈" : "👁️"}
                </button>
              </div>
            </div>

            {/* Farm Details Section */}
            <div className="farm-details-section">
              <h3>Farm Details</h3>
              {/* Farm Name */}
              <label>Farm Name</label>
              <input
                type="text"
                placeholder="Enter your farm name"
                value={farmName}
                onChange={(e) => setFarmName(e.target.value)}
              />

              {/* Farm Website */}
              <label>Farm Website</label>
              <input
                type="url"
                placeholder="Enter your farm website"
                value={farmWebsite}
                onChange={(e) => setFarmWebsite(e.target.value)}
              />

              <label>City*</label>
              <input
                type="text"
                placeholder="Enter your city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
              <label>State*</label>
              <select
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              >
                {usStates.map((st) => (
                  <option key={st.value} value={st.value}>
                    {st.label}
                  </option>
                ))}
              </select>
              <label>Zipcode*</label>
              <input
                type="text"
                placeholder="Enter your zipcode"
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
                required
                pattern="^\d{5}$"
                maxLength={5}
                minLength={5}
                title="Zipcode must be 5 digits"
              />
            </div>
          </div>
          <div className="terms-section">
            <label className="terms-container">
              <input
                type="checkbox"
                id="agreeToTerms"
                checked={agreeToTerms}
                onChange={() => setAgreeToTerms(!agreeToTerms)}
              />
              I agree to the{' '}
              <a href="/TnC" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
              *
            </label>
          </div>

          <button
            type="submit"
            className="signup-button"
            disabled={isSubmitting} // Button disabled if submitting
          >
            {isSubmitting ? "Signing Up..." : "Sign Up"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
