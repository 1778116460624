import { createClient } from '@supabase/supabase-js';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_API_KEY = process.env.REACT_APP_SUPABASE_API_KEY;

if (!SUPABASE_URL || !SUPABASE_API_KEY) {
  throw new Error('Supabase URL or API key is missing.');
}

const supabase = createClient(SUPABASE_URL, SUPABASE_API_KEY);

export default supabase;
