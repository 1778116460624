export const awsConfig = {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION || 'us-east-2', // Set a default region
  bucketName: process.env.REACT_APP_BUCKET_NAME,
};

if (!awsConfig.accessKeyId || !awsConfig.secretAccessKey || !awsConfig.bucketName) {
  throw new Error('AWS Config values are missing.');
}
