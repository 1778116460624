import React from 'react';
import './ProductCard.css';
import { FaTimes } from 'react-icons/fa';

const ProductCard = ({ product, onEdit, onDelete }) => (
  <div className="product-card">
    <button className="delete-button" onClick={onDelete}>
      <FaTimes />
    </button>
    <img src={product.picture_aws_link1} alt={product.name} className="product-image" />
    <button className="edit-button" onClick={() => onEdit(product)}>Edit</button>
    <div className="product-info">
      <p className="product-name">{product.name}</p>
      <p className="product-breed">{product.breed}</p>
      <p className="product-price">Price: ${product.price}</p>
    </div>
  </div>
);

export default ProductCard;