import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from './supabaseClient';
import './ResetPassword.css';
import Header from './Header';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  useEffect(() => {
    console.log("ResetPassword component mounted");
  
    // Get the full hash fragment
    const hash = window.location.hash.substring(1); // Remove the '#' character
    console.log("Full hash fragment:", hash);
  
    // Parse the hash fragment into key-value pairs
    const params = new URLSearchParams(hash);
    const accessToken = params.get('access_token');
    const refreshToken = params.get('refresh_token');
  
    console.log("Extracted access token:", accessToken);
    console.log("Extracted refresh token:", refreshToken);
  
    if (accessToken && refreshToken) {
      supabase.auth.setSession({ access_token: accessToken, refresh_token: refreshToken })
        .then(({ data, error }) => {
          if (error) {
            console.error("Error setting session:", error);
            setError("Invalid or expired reset token. Please request a new reset link.");
          } else {
            console.log("Session successfully set:", data);
          }
        })
        .catch((err) => {
          console.error("Unexpected error setting session:", err);
          setError("An unexpected error occurred while setting the session.");
        });
    } else {
      console.error("Access token or refresh token is missing in the URL hash");
      setError('Invalid or missing reset token. Please try requesting a new reset link.');
    }
  }, []);
   
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    console.log("Attempting to reset password");
    console.log("New password:", newPassword);
    console.log("Confirm password:", confirmPassword);

    if (newPassword !== confirmPassword) {
      console.error("Passwords do not match");
      setError("Passwords do not match.");
      return;
    }

    try {
      const { data, error } = await supabase.auth.updateUser({ password: newPassword });
      console.log("UpdateUser response:", { data, error });

      if (error) {
        console.error("Error updating password:", error.message);
        setError("Error updating password: " + error.message);
      } else {
        console.log("Password updated successfully:", data);
        setMessage('Your password has been successfully updated. Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000);
      }
    } catch (err) {
      console.error("Unexpected error updating password:", err);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="reset-password-page-container33">
      <Header />
      <div className="reset-password-form-container33">
        <h2>Reset Password</h2>
        {error && <p className="error-message33">{error}</p>}
        {!error && (
          <form onSubmit={handleResetPassword} className="reset-password-form33">
  <div className="password-container33">
    <input
      type={showNewPassword ? "text" : "password"}
      placeholder="Enter new password"
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
      required
    />
    <button
      type="button"
      className="toggle-password33"
      onClick={() => setShowNewPassword(!showNewPassword)}
    >
      {showNewPassword ? "🙈" : "👁️"}
    </button>
  </div>
  <div className="password-container33">
    <input
      type={showConfirmPassword ? "text" : "password"}
      placeholder="Enter new password again"
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      required
    />
    <button
      type="button"
      className="toggle-password33"
      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
    >
      {showConfirmPassword ? "🙈" : "👁️"}
    </button>
  </div>
  <button type="submit" className="reset-button33">Reset</button>
</form>

        )}
        {message && <p className="success-message33">{message}</p>}
      </div>
    </div>
  );
};

export default ResetPassword;
