import React, { useState, useEffect, useRef } from 'react';
import { FaPaperPlane, FaImage } from 'react-icons/fa';
import Header from './Header';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import supabase from './supabaseClient';
import { awsConfig } from './awsConfig';
import AWS from 'aws-sdk';
import './Messages.css';

AWS.config.update({
  accessKeyId: awsConfig.accessKeyId,
  secretAccessKey: awsConfig.secretAccessKey,
  region: awsConfig.region,
});

const s3 = new AWS.S3();

const MessagingPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const tempUserId = localStorage.getItem('user_uuid');

  const [contacts, setContacts] = useState([]);
  const [currentContactId, setCurrentContactId] = useState(id || null);
  const [messageText, setMessageText] = useState(location.state?.prefilledMessage || ''); // Pre-fill message if available
  const [searchTerm, setSearchTerm] = useState('');
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (id) {
      setCurrentContactId(id);
    }
  }, [id]);

  useEffect(() => {
    const fetchActiveContacts = async () => {
      const { data: messagesData, error } = await supabase
        .from('messaging')
        .select('sender_uuid, receiver_uuid, receiver_viewed')
        .or(`sender_uuid.eq.${tempUserId},receiver_uuid.eq.${tempUserId}`);

      if (error) {
        console.error('Error fetching active contacts:', error);
        return;
      }

      const unreadCounts = {};
      messagesData.forEach((message) => {
        const contactId = message.sender_uuid === tempUserId ? message.receiver_uuid : message.sender_uuid;
        if (message.receiver_uuid === tempUserId && !message.receiver_viewed) {
          unreadCounts[contactId] = (unreadCounts[contactId] || 0) + 1;
        }
      });

      const activeUserIds = Array.from(
        new Set(
          messagesData
            .flatMap(({ sender_uuid, receiver_uuid }) => [sender_uuid, receiver_uuid])
            .filter((uuid) => uuid !== tempUserId)
        )
      );

      const { data: usersData, error: usersError } = await supabase
        .from('users')
        .select('user_uuid, name, image')
        .in('user_uuid', activeUserIds);

      if (usersError) console.error('Error fetching contacts:', usersError);
      else {
        const contactsWithUnread = usersData.map((user) => ({
          ...user,
          unreadCount: unreadCounts[user.user_uuid] || 0,
        }));
        setContacts(contactsWithUnread);
      }
    };

    fetchActiveContacts();
  }, []);

  useEffect(() => {
    const fetchOrCreateMessages = async () => {
      if (!currentContactId) return;

      const { data, error } = await supabase
        .from('messaging')
        .select('*')
        .or(`and(sender_uuid.eq.${tempUserId},receiver_uuid.eq.${currentContactId}),and(sender_uuid.eq.${currentContactId},receiver_uuid.eq.${tempUserId})`)
        .order('timestamp', { ascending: true });

      if (error) {
        console.error('Error fetching messages:', error);
        return;
      }

      setMessages(data);

      // Mark messages as read
      const { error: updateError } = await supabase
        .from('messaging')
        .update({ receiver_viewed: true })
        .eq('receiver_uuid', tempUserId)
        .eq('sender_uuid', currentContactId)
        .eq('receiver_viewed', false);

      if (updateError) console.error("Error updating messages as read:", updateError);
    };

    fetchOrCreateMessages();
  }, [currentContactId]);

  const handleSendMessage = async (text = null, imageUrl = null) => {
    if (!currentContactId) return;

    const messageContent = imageUrl || text || messageText;
    if (!messageContent) return;

    const newMessage = {
      message_uuid: crypto.randomUUID(),
      sender_uuid: tempUserId,
      receiver_uuid: currentContactId,
      message: messageContent,
      timestamp: new Date().toISOString(),
    };

    const { error } = await supabase.from('messaging').insert(newMessage);

    if (error) console.error('Error sending message:', error);
    else {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setMessageText('');
      scrollToBottom();
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileName = `${Date.now()}-${file.name}`;
    const params = {
      Bucket: awsConfig.bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadResult = await s3.upload(params).promise();
      const imageUrl = uploadResult.Location;

      await handleSendMessage(null, imageUrl);
    } catch (error) {
      console.error('Error uploading image to S3:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredContacts = contacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleContactClick = (contactId) => {
    setCurrentContactId(contactId);
    navigate(`/messages/${contactId}`);
  };

  return (
    <>
      <Header />
      <div className="messaging-page">
        <div className="sidebar">
          <input
            type="text"
            placeholder="Search"
            className="search-bar"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="contacts-list">
            {filteredContacts.map(contact => (
              <div
                key={contact.user_uuid}
                className={`contact ${currentContactId === contact.user_uuid ? 'active' : ''}`}
                onClick={() => handleContactClick(contact.user_uuid)}
              >
                <img src={contact.image || 'profile-placeholder.png'} alt={contact.name} className="profile-pic" />
                <span className="contact-name">
                  {contact.name} 
                  {contact.unreadCount > 0 && <span className="unread-count">({contact.unreadCount})</span>}
                </span>
              </div>
            ))}
          </div>
        </div>

        {currentContactId ? (
          <div className="chat-section">
            <div className="chat-header">
              <img
                src={
                  contacts.find(contact => contact.user_uuid === currentContactId)?.image ||
                  'profile-placeholder.png'
                }
                alt="Contact"
                className="header-profile-pic"
              />
              <span className="header-contact-name">
                {contacts.find(contact => contact.user_uuid === currentContactId)?.name || 'Chat'}
              </span>
            </div>

            <div className="messages">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message ${message.sender_uuid === tempUserId ? 'sent' : 'received'} ${!message.receiver_viewed && message.receiver_uuid === tempUserId ? 'unread' : ''}`}
                >
                  {message.message.startsWith('https://') ? (
                    <img src={message.message} alt="Uploaded" className="message-image" />
                  ) : (
                    <span>{message.message}</span>
                  )}
                  <div className="timestamp">{new Date(message.timestamp).toLocaleString()}</div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>

            <div className="message-input">
              <input
                type="text"
                placeholder="Aa"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              />
              <label htmlFor="image-upload" className="image-upload-button">
                <FaImage />
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />
              <button className="send-button" onClick={() => handleSendMessage()}>
                <FaPaperPlane />
              </button>
            </div>
          </div>
        ) : (
          <div className="blank-chat-section">
          </div>
        )}
      </div>
    </>
  );
};

export default MessagingPage;
