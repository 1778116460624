import React, { useState, useEffect } from 'react';
import './ProductForm.css';
import AWS from 'aws-sdk';
import { awsConfig } from './awsConfig';
import supabase from './supabaseClient';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const ProductForm = ({ product, onSave, onCancel }) => {
  
  const [formData, setFormData] = useState({
    name: '',
    breed: '',
    price: '',
    
    category: 'Semen',
    images: product?.images || [],
    breedweb: '',
    breedascn: '',
    breedascnno: '',
    Description: '',
    color: '',
    Semen_CED: '',
    Semen_SC: '',
    Semen_BW: '',
    Semen_WW: '',
    Semen_YW: '',
    Semen_DMI: '',
    Semen_Docility: '',
    Semen_FA: '',
    Semen_HP: '',
    Semen_CEM: '',
    Semen_Milk: '',
    Semen_MW: '',
    Semen_MH: '',
    Semen_CW: '',
    Semen_CM: '',
    Semen_CR: '',
    Semen_Fat: '',
    Semen_F11: '',
    Semen_F13: '',
    Semen_CHS: '',
    Semen_Band3: '',
    dateOfBirth: '',
    Embryo_MY: '',
    Embryo_CI: '',
    Embryo_DIR: '',
    Embryo_DTRS: '',
    Embryo_GL: '',
    Embryo_200DW: '',
    Embryo_BW: '',
    Embryo_400DW: '',
    Embryo_600DW: '',
    Embryo_MCW: '',
    Embryo_200DM: '',
    Embryo_SS: '',
    Embryo_CW: '',
    Embryo_EMA: '',
    Embryo_RF: '',
    Embryo_RBY: '',
    Embryo_IMF: '',
  });

  const navigate = useNavigate(); // Initialize useNavigate
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('product-form-overlay')) {
      // Close the modal and stay on the sell page
      onCancel();
    }
  };

  useEffect(() => {
    if (product) {
      console.log('Product received in ProductForm:', product);
      setFormData({
        id: product.id || null,
        name: product.name || '',
        breed: product.breed || '',
        price: product.price || '',
        dateOfBirth: product.birthdate ? formatDateForInput(product.birthdate) : '', // Format the date for input
        category: product.category || 'Semen',
        image: product.picture_aws_link1 || '', // Populate the image link
        breedweb: product.breed_website || '',
        breedascn: product.breed_association || '',
        breedascnno: product.breed_association_number || '',
        Description: product.description || '',
        color: product.color || '',
        Semen_CED: product.calving_ease_direct || '',
        Semen_SC: product.scrotal_circumference || '',
        Embryo_BW: product.birth_weight || '', // For embryos
        Semen_BW: product.birth_weight || '',  // For semen
        Semen_WW: product.weaning_weight || '',
        Semen_YW: product.yearling_weight || '',
        Semen_DMI: product.dry_matter_intake || '',
        Semen_Docility: product.docility || '',
        Semen_FA: product.foot_angle || '',
        Semen_HP: product.heifer_pregnancy || '',
        Semen_CEM: product.calving_ease_maternal || '',
        Semen_Milk: product.milk || '',
        Semen_MW: product.mature_weight || '',
        Semen_MH: product.mature_height || '',
        Semen_CW: product.carcass_weight || '',
        Semen_CM: product.carcass_marbling || '',
        Semen_CR: product.carcass_ribeye || '',
        Semen_Fat: product.carcass_fat || '',
        Semen_F11: product.f11 || '',
        Semen_F13: product.f13 || '',
        Semen_CHS: product.chs || '',
        Semen_Band3: product.band3 || '',
        Embryo_MY: product.milk_yield || '',
        Embryo_CI: product.calving_interval || '',
        Embryo_DIR: product.calving_ease_dir || '',
        Embryo_DTRS: product.calving_ease_dtrs || '',
        Embryo_GL: product.gestation_length || '',
        Embryo_200DW: product.day_200_weight || '',
        Embryo_400DW: product.day_400_weight || '',
        Embryo_600DW: product.day_600_weight || '',
        Embryo_MCW: product.maternal_cow_weight || '',
        Embryo_200DM: product.day_200_milk || '',
        Embryo_SS: product.scrotal_size || '',
        Embryo_CW: product.carcase_weight || '',
        Embryo_EMA: product.eye_muscle_area || '',
        Embryo_RF: product.rib_fat || '',
        Embryo_RBY: product.retail_beef_yield || '',
        Embryo_IMF: product.imf || '',
      });
    }
  }, [product]);

  const handleCategorySelect = (category) => {
    setFormData({ ...formData, category });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0]; // Only handle one file
  
    if (!file) return;
  
    const uploadParams = {
      Bucket: awsConfig.bucketName,
      Key: `product-images/${Date.now()}-${file.name}`,
      Body: file,
      ContentType: file.type,
    };
  
    try {
      const data = await s3.upload(uploadParams).promise();
      setFormData((prevState) => ({ ...prevState, image: data.Location }));
    } catch (err) {
      console.error('Error uploading file to AWS S3:', err);
    }
  };
  


  const formatDateForInput = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('-'); // Assuming the date from the backend is in 'yyyy-mm-dd'
    return `${year}-${month}-${day}`;
  };

  const handleRemoveImage = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      images: prevState.images.filter((_, i) => i !== index),
    }));
  };


  const handleImageReorder = (index, direction) => {
    const newImages = [...formData.images];
    const swapIndex = direction === 'left' ? index - 1 : index + 1;

    if (swapIndex >= 0 && swapIndex < newImages.length) {
      [newImages[index], newImages[swapIndex]] = [newImages[swapIndex], newImages[index]];
      setFormData({ ...formData, images: newImages });
    }
  };
  const formatDateForSupabase = (date) => {
    const [year, day, month] = date.split('-');
    return `${year}-${month}-${day}`;
  };
  




  const tempUUID = '44084b41-a749-4a20-9808-95b309643fa2';

  const s3 = new AWS.S3({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
    region: awsConfig.region,
  });





  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Check if onSave is defined
    if (typeof onSave !== "function") {
      console.error("onSave function is not defined");
      return;
    }
  
    // Log the form data for debugging
    console.log("Form data on submit:", formData);
  
    const formattedDateOfBirth = formData.dateOfBirth
    ? formatDateForSupabase(formData.dateOfBirth)
    : null;

    // Prepare data for saving based on the selected category
    const dataToSave = {
      
      id: formData.id,
      picture_aws_link1: formData.image, // Add the image URL here
      name: formData.name,
      breed: formData.breed,
      price: formData.price,
      category: formData.category,
      color: formData.color,
      dateOfBirth: formattedDateOfBirth,  // Use the formatted date
      description: formData.Description,
      breed_website: formData.breedweb,
      breed_association: formData.breedascn,
      breed_association_number: formData.breedascnno,
      ...(formData.category === 'Semen' && {
        calving_ease_direct: formData.Semen_CED,
        birth_weight: formData.Semen_BW,
        weaning_weight: formData.Semen_WW,
        yearling_weight: formData.Semen_YW,
        dry_matter_intake: formData.Semen_DMI,
        scrotal_circumference: formData.Semen_SC,
        docility: formData.Semen_Docility,
        foot_angle: formData.Semen_FA,
        heifer_pregnancy: formData.Semen_HP,
        calving_ease_maternal: formData.Semen_CEM,
        milk: formData.Semen_Milk,
        mature_weight: formData.Semen_MW,
        mature_height: formData.Semen_MH,
        carcass_weight: formData.Semen_CW,
        carcass_marbling: formData.Semen_CM,
        carcass_ribeye: formData.Semen_CR,
        carcass_fat: formData.Semen_Fat,
        f11: formData.Semen_F11,
        f13: formData.Semen_F13,
        chs: formData.Semen_CHS,
        band3: formData.Semen_Band3,
      }),
      ...(formData.category === 'Embryos' && {
        milk_yield: formData.Embryo_MY,
        calving_interval: formData.Embryo_CI,
        calving_ease_dir: formData.Embryo_DIR,
        calving_ease_dtrs: formData.Embryo_DTRS,
        gestation_length: formData.Embryo_GL,
        birth_weight: formData.Embryo_BW, // Include this line for birth weight
        day_200_weight: formData.Embryo_200DW,
        day_400_weight: formData.Embryo_400DW,
        day_600_weight: formData.Embryo_600DW,
        maternal_cow_weight: formData.Embryo_MCW,
        day_200_milk: formData.Embryo_200DM,
        scrotal_size: formData.Embryo_SS,
        carcase_weight: formData.Embryo_CW,
        eye_muscle_area: formData.Embryo_EMA,
        rib_fat: formData.Embryo_RF,
        retail_beef_yield: formData.Embryo_RBY,
        imf: formData.Embryo_IMF,
      }),
    };
  
    console.log("Filtered data to save:", dataToSave);
  
    onSave(dataToSave);
  };
  


  return (
    <div className="product-form-overlay" onClick={handleOverlayClick}>
      <form className="product-form" onSubmit={handleSubmit}>
        
        <button className="exit-button" onClick={onCancel}>X</button>
        <h3>{formData.id ? 'Edit Product' : 'Add Product'}</h3>

        {/* Category Selection */}
        {!formData.id && (
          <div className="category-selection">
            <button
              type="button"
              className={`category-button1 ${formData.category === 'Semen' ? 'active' : ''}`}
              onClick={() => handleCategorySelect('Semen')}
            >
              Semen
            </button>
            <button
              type="button"
              className={`category-button1 ${formData.category === 'Embryos' ? 'active' : ''}`}
              onClick={() => handleCategorySelect('Embryos')}
            >
              Embryos
            </button>
          </div>
        )}


<div className="image-upload-section">
  <label>Upload Image</label>
  <div className="custom-file-upload">
    <button
      type="button"
      onClick={() => document.getElementById('image-upload-input').click()}
    >
      Choose Image
    </button>
    <input
      type="file"
      accept="image/*"
      id="image-upload-input"
      style={{ display: 'none' }}
      onChange={handleImageUpload}
    />
  </div>

  {formData.image && (
    <div className="image-preview">
      <img src={formData.image} alt="Uploaded" />
      <button
        type="button"
        className="remove-image"
        onClick={() => setFormData((prevState) => ({ ...prevState, image: null }))}
      >
        X
      </button>
    </div>
  )}
</div>


        {/* Common Fields */}
        <div className="form-group">
          <label>Animal Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Breed</label>
          <input
            type="text"
            name="breed"
            value={formData.breed}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Breed Website</label>
          <input
            type="text"
            name="breedweb" // Corrected name attribute
            value={formData.breedweb}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Breed Association</label>
          <input
            type="text" // Set to text if the field is textual
            name="breedascn" // Corrected name attribute
            value={formData.breedascn}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Breed Association Number</label>
          <input
            type="text" // Set to text if the field is textual
            name="breedascnno" // Corrected name attribute
            value={formData.breedascnno}
            onChange={handleChange}
            required
          />
        </div>



        <div className="form-group">
          <label>Description</label>
          <input
            type="text"
            name="Description"
            value={formData.Description}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Color</label>
          <input
            type="text"
            name="color"
            value={formData.color}
            onChange={handleChange}
            required
          />
        </div>





        <div className="form-group">
          <label>Price + Shipping in the US</label>
          <label>Reminder: We take a 8% commission on the sale</label>

          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
          />
        </div>

        {/* Conditional Fields for Semen */}
        {formData.category === 'Semen' && (
          <>
<div className="form-group">
  <label>Date of Birth</label>
  <input
    type="date"
    name="dateOfBirth"
    value={formData.dateOfBirth}
    onChange={handleChange}
  />
</div>


            <div className="form-group">
              <label>Calving Ease Direct</label>
              <input
                type="number"
                name="Semen_CED"
                value={formData.Semen_CED}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Scrotal Circumference</label>
              <input
                type="number"
                name="Semen_SC"
                value={formData.Semen_SC}
                onChange={handleChange}
                required
              />
            </div>


            <div className="form-group">
              <label>Birth Weight</label>
              <input
                type="number"
                name="Semen_BW"
                value={formData.Semen_BW}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Weaning Weight</label>
              <input
                type="number"
                name="Semen_WW"
                value={formData.Semen_WW}
                onChange={handleChange}
                required
              />
            </div>


            <div className="form-group">
              <label>Yearling Weight</label>
              <input
                type="number"
                name="Semen_YW"
                value={formData.Semen_YW}
                onChange={handleChange}
                required
              />
            </div>


            <div className="form-group">
              <label>Dry Matter Intake</label>
              <input
                type="number"
                name="Semen_DMI"
                value={formData.Semen_DMI}
                onChange={handleChange}
                required
              />
            </div>


            <div className="form-group">
              <label>Docility</label>
              <input
                type="number"
                name="Semen_Docility"
                value={formData.Semen_Docility}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Foot Angle</label>
              <input
                type="number"
                name="Semen_FA"
                value={formData.Semen_FA}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Heifer Pregnancy</label>
              <input
                type="number"
                name="Semen_HP"
                value={formData.Semen_HP}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Calving Ease Maternal</label>
              <input
                type="number"
                name="Semen_CEM"
                value={formData.Semen_CEM}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Milk</label>
              <input
                type="number"
                name="Semen_Milk"
                value={formData.Semen_Milk}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Mature Weight</label>
              <input
                type="number"
                name="Semen_MW"
                value={formData.Semen_MW}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Mature Height</label>
              <input
                type="number"
                name="Semen_MH"
                value={formData.Semen_MH}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Carcass Weight</label>
              <input
                type="number"
                name="Semen_CW"
                value={formData.Semen_CW}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Carcass Marbling</label>
              <input
                type="number"
                name="Semen_CM"
                value={formData.Semen_CM}
                onChange={handleChange}
                required
              />
            </div>


            <div className="form-group">
              <label>Carcass Ribeye</label>
              <input
                type="number"
                name="Semen_CR"
                value={formData.Semen_CR}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Carcass Fat</label>
              <input
                type="number"
                name="Semen_Fat"
                value={formData.Semen_Fat}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>F11</label>
              <input
                type="number"
                name="Semen_F11"
                value={formData.Semen_F11}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>F13</label>
              <input
                type="number"
                name="Semen_F13"
                value={formData.Semen_F13}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>CHS</label>
              <input
                type="number"
                name="Semen_CHS"
                value={formData.Semen_CHS}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Band3</label>
              <input
                type="number"
                name="Semen_Band3"
                value={formData.Semen_Band3}
                onChange={handleChange}
                required
              />
            </div>
          </>
        )}

        {/* Conditional Fields for Embryos */}
        {formData.category === 'Embryos' && (
          <>
<div className="form-group">
  <label>Date of Birth</label>
  <input
    type="date"
    name="dateOfBirth"
    value={formData.dateOfBirth}
    onChange={handleChange}
  />
</div>



            <div className="form-group">
              <label>Milk Yield          </label>
              <input
                type="number"
                name="Embryo_MY"
                value={formData.Embryo_MY}
                onChange={handleChange}
                required
              />
            </div>


            <div className="form-group">
              <label>Calving Interval          </label>
              <input
                type="number"
                name="Embryo_CI"
                value={formData.Embryo_CI}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Calving Ease DIR          </label>
              <input
                type="number"
                name="Embryo_DIR"
                value={formData.Embryo_DIR}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Calving Ease DTRS          </label>
              <input
                type="number"
                name="Embryo_DTRS"
                value={formData.Embryo_DTRS}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Gestation Length          </label>
              <input
                type="number"
                name="Embryo_GL"
                value={formData.Embryo_GL}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Birth Weight          </label>
              <input
                type="number"
                name="Embryo_BW"
                value={formData.Embryo_BW}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>200 Day Weight          </label>
              <input
                type="number"
                name="Embryo_200DW"
                value={formData.Embryo_200DW}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>400 Day Weight          </label>
              <input
                type="number"
                name="Embryo_400DW"
                value={formData.Embryo_400DW}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>600 Day Weight          </label>
              <input
                type="number"
                name="Embryo_600DW"
                value={formData.Embryo_600DW}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Maternal Cow Weight</label>
              <input
                type="number"
                name="Embryo_MCW"
                value={formData.Embryo_MCW}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>200 Day Milk</label>
              <input
                type="number"
                name="Embryo_200DM"
                value={formData.Embryo_200DM}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Scrotal Size          </label>
              <input
                type="number"
                name="Embryo_SS"
                value={formData.Embryo_SS}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Carcase Weight   </label>
              <input
                type="number"
                name="Embryo_CW"
                value={formData.Embryo_CW}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Eye Muscle Area          </label>
              <input
                type="number"
                name="Embryo_EMA"
                value={formData.Embryo_EMA}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Rib Fat          </label>
              <input
                type="number"
                name="Embryo_RF"
                value={formData.Embryo_RF}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Retail Beef Yield          </label>
              <input
                type="number"
                name="Embryo_RBY"
                value={formData.Embryo_RBY}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>IMF          </label>
              <input
                type="number"
                name="Embryo_IMF"
                value={formData.Embryo_IMF}
                onChange={handleChange}
                required
              />
            </div>

          </>
        )}

        <button type="submit" className="save-button">Save Changes</button>
        <button type="button" className="cancel-button" onClick={onCancel}>Cancel</button>
      </form>
    </div>
  );
};

export default ProductForm;