import React, { useState, useEffect } from 'react';
import { FaHeart, FaPaperPlane } from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon } from 'react-share';
import './SireCard.css';
import supabase from './supabaseClient';

const SireCard = ({ id, imageUrl, name, breed, location, price, rating, selectedFeatures, product }) => {
  const userId = localStorage.getItem('user_uuid');
  const [isFavorited, setIsFavorited] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  // Check if item is already saved when component mounts
  useEffect(() => {
    const checkIfFavorited = async () => {
      const { data, error } = await supabase
        .from('saved')
        .select('saved_uuid')
        .eq('user_uuid', userId)
        .eq(product.sire_uuid ? 'sire_uuid' : 'dam_uuid', id)
        .single();

      if (error) {
        console.error("Error checking saved status:", error);
      } else {
        setIsFavorited(!!data); // Set to true if data exists, otherwise false
      }
    };

    checkIfFavorited();
  }, [id, product, userId]);

  const handleHeartClick = async (e) => {
    e.stopPropagation();

    if (isFavorited) {
      // Remove from saved items
      const { error } = await supabase
        .from('saved')
        .delete()
        .eq('user_uuid', userId)
        .eq(product.sire_uuid ? 'sire_uuid' : 'dam_uuid', id);

      if (error) {
        console.error('Error removing saved item:', error);
      } else {
        setIsFavorited(false);
      }
    } else {
      // Add to saved items
      const { error } = await supabase.from('saved').insert([
        {
          saved_uuid: crypto.randomUUID(), // Generate unique ID
          user_uuid: userId,
          sire_uuid: product.sire_uuid || null,
          dam_uuid: product.dam_uuid || null,
        },
      ]);

      if (error) {
        console.error('Error saving item:', error);
      } else {
        setIsFavorited(true);
      }
    }
  };

  const handleShareClick = (e) => {
    e.stopPropagation();
    setIsShareModalOpen(true); // Open the share modal
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopySuccess('Link copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear success message after 2 seconds
    }).catch(err => {
      console.error("Failed to copy: ", err);
    });
  };

  const handleCardClick = () => {
    window.open(`/product/${id}`, '_blank');
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      setIsShareModalOpen(false);
    }
  };

  const shareUrl = `${window.location.origin}/product/${id}`;
  const shareTitle = `Check out this ${name} (${breed}) available at ${location}!`;

  return (
    <>
      <div className="sire-card" onClick={handleCardClick}>
        <div className="image-container">
          <img src={imageUrl} alt={name} className="sire-image" />
          <div className="rating-container">
            <span className="rating-score">{rating}/5</span>
          </div>
          <button className="heart-button" onClick={handleHeartClick}>
            <FaHeart color={isFavorited ? 'red' : 'black'} />
          </button>
          <button className="share-button11" onClick={handleShareClick}>
            <FaPaperPlane />
          </button>
        </div>
        <div className="sire-details">
          {selectedFeatures.name && <h3>{name}</h3>}
          {selectedFeatures.breed && <p>{breed}</p>}
          {selectedFeatures.location && <p>{location}</p>}
          {selectedFeatures.price && <p>${price}</p>}
          {selectedFeatures.bullced && <p>Calving Ease Direct: {product.calving_ease_direct}</p>}
          {selectedFeatures.bullbw && <p>Birth Weight: {product.birth_weight}</p>}
          {selectedFeatures.bullww && <p>Weaning Weight: {product.weaning_weight}</p>}
          {selectedFeatures.bullyw && <p>Yearling Weight: {product.yearling_weight}</p>}
          {selectedFeatures.milkYield && <p>Milk Yield: {product.milk_yield}</p>}
          {selectedFeatures.calvingInterval && <p>Calving Interval: {product.calving_interval}</p>}
          {selectedFeatures.ced && <p>Calving Ease Direct: {product.calving_ease_dir}</p>}
          {selectedFeatures.bw && <p>Birth Weight: {product.birth_weight}</p>}
        </div>
      </div>

      {/* Share Modal */}
      {isShareModalOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-content">
            <button className="close-modal-button" onClick={() => setIsShareModalOpen(false)}>
              &times;
            </button>
            <h3>Share this product</h3>
            <div className="share-buttons">
              <FacebookShareButton url={shareUrl} quote={shareTitle}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={shareTitle}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <EmailShareButton url={shareUrl} subject={shareTitle} body="Check this out!">
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
            <button onClick={handleCopyLink} className="copy-link-button">
              Copy Link
            </button>
            {copySuccess && <span className="copy-success-message">{copySuccess}</span>}
          </div>
        </div>
      )}
    </>
  );
};

export default SireCard;
