import React, { useState, useEffect } from 'react';
import './SellPage.css';
import ProductCard from './ProductCard';
import ProductForm from './ProductForm';
import Header from './Header';
import supabase from './supabaseClient';

const userUUID = localStorage.getItem('user_uuid');

const SellPage = () => {
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      const { data: siresData, error: siresError } = await supabase
        .from('sires')
        .select('*')
        .eq('user_uuid', userUUID);
  
      const { data: damsData, error: damsError } = await supabase
        .from('dams')
        .select('*')
        .eq('user_uuid', userUUID);
  
      if (siresError) console.error('Error fetching sires:', siresError);
      if (damsError) console.error('Error fetching dams:', damsError);
  
      const formattedSires = siresData?.map(sire => ({
        ...sire,
        id: sire.sire_uuid, // Map sire_uuid to id for consistency
        imageUrl: sire.picture_aws_link1,
        category: 'Semen',
      }));
  
      const formattedDams = damsData?.map(dam => ({
        ...dam,
        id: dam.dam_uuid, // Map dam_uuid to id for consistency
        imageUrl: dam.picture_aws_link1,
        category: 'Embryos',
      }));
  
      setProducts([...(formattedSires || []), ...(formattedDams || [])]);
    };
  
    fetchProducts();
  }, []);
  

  const handleAddProduct = () => {
    setCurrentProduct(null);
    setIsFormVisible(true);
  };

  const handleEditProduct = (product) => {
    setCurrentProduct(product); // Pass the existing product to ProductForm
    setIsFormVisible(true);
  };

  const handleSaveProduct = async (product) => {
    const table = product.category === 'Semen' ? 'sires' : 'dams';
    const uuidColumn = product.category === 'Semen' ? 'sire_uuid' : 'dam_uuid';

    const data = {
      user_uuid: userUUID,
      picture_aws_link1: product.picture_aws_link1 || null,
      picture_aws_link2: product.picture_aws_link2 || null,
      picture_aws_link3: product.picture_aws_link3 || null,
      picture_aws_link4: product.picture_aws_link4 || null,
      picture_aws_link5: product.picture_aws_link5 || null,
      picture_aws_link6: product.picture_aws_link6 || null,
      picture_aws_link7: product.picture_aws_link7 || null,
      picture_aws_link8: product.picture_aws_link8 || null,  
      name: product.name || '',
      breed: product.breed || '',
      price: product.price || 0,
      description: product.description || '',
      breed_website: product.breed_website || '',
      breed_association: product.breed_association || '',
      breed_association_number: product.breed_association_number || '',
      color: product.color || '',
      birthdate: product.dateOfBirth ? product.dateOfBirth : null,
      ...(product.category === 'Semen' ? {
        calving_ease_direct: product.calving_ease_direct || 0,
        weaning_weight: product.weaning_weight || 0,
        yearling_weight: product.yearling_weight || 0,
        birth_weight: product.birth_weight || product.Semen_BW || 0, // Ensuring birth_weight is mapped

        dry_matter_intake: product.dry_matter_intake || 0,
        scrotal_circumference: product.scrotal_circumference || 0,
        docility: product.docility || 0,
        foot_angle: product.foot_angle || 0,
        heifer_pregnancy: product.heifer_pregnancy || 0,
        calving_ease_maternal: product.calving_ease_maternal || 0,
        milk: product.milk || 0,
        mature_weight: product.mature_weight || 0,
        mature_height: product.mature_height || 0,
        carcass_weight: product.carcass_weight || 0,
        carcass_marbling: product.carcass_marbling || 0,
        carcass_ribeye: product.carcass_ribeye || 0,
        carcass_fat: product.carcass_fat || 0,
        f11: product.f11 || 0,
        f13: product.f13 || 0,
        chs: product.chs || 0,
        band3: product.band3 || 0,
      } : {
        milk_yield: product.milk_yield || 0,
        calving_interval: product.calving_interval || 0,
        calving_ease_dir: product.calving_ease_dir || 0,
        birth_weight: product.birth_weight || product.Embryo_BW || 0, // Ensuring birth_weight is mapped

        calving_ease_dtrs: product.calving_ease_dtrs || 0,
        gestation_length: product.gestation_length || 0,
        day_200_weight: product.day_200_weight || 0,
        day_400_weight: product.day_400_weight || 0,
        day_600_weight: product.day_600_weight || 0,
        maternal_cow_weight: product.maternal_cow_weight || 0,
        day_200_milk: product.day_200_milk || 0,
        scrotal_size: product.scrotal_size || 0,
        carcase_weight: product.carcase_weight || 0,
        eye_muscle_area: product.eye_muscle_area || 0,
        rib_fat: product.rib_fat || 0,
        retail_beef_yield: product.retail_beef_yield || 0,
        imf: product.imf || 0,
      }),
    };
  
    let result;
    if (product.id) { // Check if the product has an id (edit mode)
      result = await supabase.from(table).update(data).eq(uuidColumn, product.id);
      window.location.reload(); // Full page reload to refresh the data
    } else { // No id means it's a new product (add mode)
      result = await supabase.from(table).insert([data]);
      window.location.reload(); // Full page reload to refresh the data
    }
  
    if (result.error) {
      console.error(`Error ${product.id ? 'updating' : 'inserting'} product:`, result.error);
    } else {
      setIsFormVisible(false);
      const newId = result.data && result.data[0] ? result.data[0][uuidColumn] : null;
      setProducts((prevProducts) => {
        if (product.id) {
          // Update the existing product in the list
          return prevProducts.map(p => (p.id === product.id ? { ...p, ...product } : p));
        } else {
          // Add the new product with its generated id
          return [...prevProducts, { ...product, id: newId }];
        }
      });
    }
  };
        
  const handleDeleteProduct = async (id, category) => {
    const confirmed = window.confirm("Are you sure you want to delete this product?");
    if (!confirmed) return;

    const table = category === 'Semen' ? 'sires' : 'dams';
    const uuidColumn = category === 'Semen' ? 'sire_uuid' : 'dam_uuid';

    const { error } = await supabase.from(table).delete().eq(uuidColumn, id);

    if (error) {
      console.error('Error deleting product:', error);
    } else {
      setProducts(products.filter(p => p.id !== id));
    }
  };

  return (
    <div className="sell-page-container">
      <Header />
      <div className="sell-page">
        <div className="product-grid">
          <div className="add-product-card" onClick={handleAddProduct}>
            <span>+</span>
          </div>
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onEdit={() => handleEditProduct(product)}
              onDelete={() => handleDeleteProduct(product.id, product.category)}
            />
          ))}
        </div>
        {isFormVisible && (
          <ProductForm
            product={currentProduct}
            onSave={handleSaveProduct}
            onCancel={() => setIsFormVisible(false)}
          />
        )}
      </div>
    </div>
  );
};

export default SellPage;