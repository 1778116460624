import React, { useState, useEffect } from 'react';
import './Marketplace.css';
import SireCard from './SireCard';
import Header from './Header';
import supabase from './supabaseClient';
import toggles from '../assets/settings-sliders.png'; 
import { isWithinDistance } from '../utils/distanceFilter';

const Marketplace = () => {
  const userId = localStorage.getItem('user_uuid');
  const [userZip, setUserZip] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('sires');
  const [sliderValue, setSliderValue] = useState(2800);
  const [products, setProducts] = useState([]);
  // const sidebarToggle = document.querySelector('.sidebar-toggle');
  // const filterSidebar = document.querySelector('.filter-sidebar');
  // const productsGrid = document.querySelector('.products-grid');

  // sidebarToggle.addEventListener('click', () => {
  //   filterSidebar.classList.toggle('visible');
  //   productsGrid.classList.toggle('full-width');
  // });



  const [breed, setBreed] = useState("all");
  const [ageRange, setAgeRange] = useState({ min: "", max: "" });
  const [priceRange, setPriceRange] = useState({ min: "", max: "" });
  const [color, setColor] = useState("all");
  const [epdValues, setEpdValues] = useState({
    Semen_calving_ease_direct: { min: "", max: "" },
    Semen_scrotal_circumference: { min: "", max: "" },
    Semen_birth_weight: { min: "", max: "" },
    Semen_weaning_weight: { min: "", max: "" },
    Semen_yearling_weight: { min: "", max: "" },
    Semen_dry_matter_intake: { min: "", max: "" },
    Semen_docility: { min: "", max: "" },
    Semen_foot_angle: { min: "", max: "" },
    Semen_heifer_pregnancy: { min: "", max: "" },
    Semen_calving_ease_maternal: { min: "", max: "" },
    Semen_milk: { min: "", max: "" },
    Semen_mature_weight: { min: "", max: "" },
    Semen_mature_height: { min: "", max: "" },
    Semen_carcass_weight: { min: "", max: "" },
    Semen_carcass_marbling: { min: "", max: "" },
    Semen_carcass_ribeye: { min: "", max: "" },
    Semen_carcass_fat: { min: "", max: "" },
    Semen_f11: { min: "", max: "" },
    Semen_f13: { min: "", max: "" },
    Semen_chs: { min: "", max: "" },
    Semen_Band3: { min: "", max: "" },

    Embryo_milk_yield: { min: "", max: "" },
    Embryo_calving_interval: { min: "", max: "" },
    Embryo_calving_ease_DIR: { min: "", max: "" },
    Embryo_calving_ease_DTRS: { min: "", max: "" },
    Embryo_gestation_length: { min: "", max: "" },
    Embryo_birth_weight: { min: "", max: "" },
    Embryo_200_day_weight: { min: "", max: "" },
    Embryo_400_day_weight: { min: "", max: "" },
    Embryo_600_day_weight: { min: "", max: "" },
    Embryo_maternal_cow_weight: { min: "", max: "" },
    Embryo_200_day_milk: { min: "", max: "" },
    Embryo_scrotal_size: { min: "", max: "" },
    Embryo_carcass_weight: { min: "", max: "" },
    Embryo_eye_muscle_area: { min: "", max: "" },
    Embryo_rib_fat: { min: "", max: "" },
    Embryo_retail_beef_yield: { min: "", max: "" },
    Embryo_IMF: { min: "", max: "" },
  });


  const defaultSireFeatures = {
    name: true,
    breed: true,
    location: true,
    price: true,
    bullced: false,
    bullbw: false,
    bullww: false,
    bullyw: false,
  };


  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarToggleStyle, setSidebarToggleStyle] = useState({
    backgroundColor: '#000000',
    border: '2px solid #000000',
    width: 'fit-content',
    left: '36px'
  });
  
  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 660px)');
    
    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setSidebarVisible(true);
        setSidebarToggleStyle({
          backgroundColor: '#8b8b8b',
          border: '2px solid #8b8b8b',
          width: '310px',
          left: '50%'
        });
      }
    };
  
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);
  
    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);
  
  const toggleSidebar = () => {
    if (window.innerWidth < 660) {
      setSidebarVisible(prevVisible => !prevVisible);
      setSidebarToggleStyle(prevStyle => ({
        backgroundColor: prevStyle.backgroundColor === '#000000' ? '#8b8b8b' : '#000000',
        border: prevStyle.border === '2px solid #000000' ? '2px solid #8b8b8b' : '2px solid #000000',
        width: prevStyle.width === 'fit-content' ? '310px' : 'fit-content',
        left: prevStyle.left === '36px' ? '50%' : '36px'
      }));
    }
  };



  const defaultDamFeatures = {
    name: true,
    breed: true,
    location: true,
    price: true,
    milkYield: false,
    calvingInterval: false,
    ced: false,
    bw: false,
  };

  const fetchUserZip = async () => {
    const { data, error } = await supabase
      .from('users')
      .select('zipcode')
      .eq('user_uuid', userId)
      .single();

    if (error) {
      console.error('Error fetching user ZIP code:', error);
    } else {
      console.error(' fetching user ZIP code:', data.zipcode);
      setUserZip(data.zipcode);
    }
  };

  const [selectedFeatures, setSelectedFeatures] = useState(defaultSireFeatures);

  const fetchData = async () => {
    if (!userZip) {
      return;
    }

    const table = selectedCategory === 'sires' ? 'sires' : 'dams';
    console.log(`Selected category: ${selectedCategory}, Querying table: ${table}`);

    // let query = supabase.from(table).select('*');

    let query = supabase.from(table).select('*').neq('user_uuid', userId);

    // General Filters
    if (breed !== 'all') {
      console.log(`Applying breed filter: ${breed}`);
      query = query.eq('breed', breed);
    }
    if (priceRange.min) {
      console.log(`Applying minimum price filter: ${priceRange.min}`);
      query = query.gte('price', priceRange.min);
    }
    if (priceRange.max) {
      console.log(`Applying maximum price filter: ${priceRange.max}`);
      query = query.lte('price', priceRange.max);
    }
    if (color !== 'all') {
      console.log(`Applying color filter: ${color}`);
      query = query.eq('color', color);
    }

    const { data, error } = await query;
    if (error) {
      console.error('Error fetching data:', error);
    } else {
      console.log("Data fetched successfully:", data);

      const filteredData = data.filter((product) => {
        // Age and Location Filters
        const isAgeInRange = ageRange.min || ageRange.max
          ? (() => {
            const age = calculateAge(product.birthdate);
            console.log(`Product ID: ${product.sire_uuid || product.dam_uuid}, Age: ${age}`);
            return (ageRange.min ? age >= ageRange.min : true) &&
              (ageRange.max ? age <= ageRange.max : true);
          })()
          : true;

        const isLocationInRange = userZip
          ? isWithinDistance(userZip, product.zipcode, sliderValue)
          : true;

        // EPD Filters
        let isEpdInRange = true;
        if (selectedCategory === 'sires') {
          console.log(`Applying EPD filters for Sires`);
          isEpdInRange =
            (epdValues.Semen_calving_ease_direct?.min
              ? product.calving_ease_direct >= epdValues.Semen_calving_ease_direct.min
              : true) &&
            (epdValues.Semen_calving_ease_direct?.max
              ? product.calving_ease_direct <= epdValues.Semen_calving_ease_direct.max
              : true) &&

            (epdValues.Semen_scrotal_circumference?.min
              ? product.scrotal_circumference >= epdValues.Semen_scrotal_circumference.min
              : true) &&
            (epdValues.Semen_scrotal_circumference?.min
              ? product.scrotal_circumference <= epdValues.Semen_scrotal_circumference.max
              : true) &&

            (epdValues.Semen_birth_weight?.min
              ? product.birth_weight >= epdValues.Semen_birth_weight.min
              : true) &&
            (epdValues.Semen_birth_weight?.max
              ? product.birth_weight <= epdValues.Semen_birth_weight.max
              : true) &&

            // Birth weight
            (epdValues.Semen_birth_weight?.min
              ? product.birth_weight >= epdValues.Semen_birth_weight.min
              : true) &&
            (epdValues.Semen_birth_weight?.max
              ? product.birth_weight <= epdValues.Semen_birth_weight.max
              : true) &&

            // Weaning weight
            (epdValues.Semen_weaning_weight?.min ? product.weaning_weight >= epdValues.Semen_weaning_weight.min : true) &&
            (epdValues.Semen_weaning_weight?.max ? product.weaning_weight <= epdValues.Semen_weaning_weight.max : true) &&

            // Yearling weight
            (epdValues.Semen_yearling_weight?.min ? product.yearling_weight >= epdValues.Semen_yearling_weight.min : true) &&
            (epdValues.Semen_yearling_weight?.max ? product.yearling_weight <= epdValues.Semen_yearling_weight.max : true) &&

            // Dry matter intake
            (epdValues.Semen_dry_matter_intake?.min ? product.dry_matter_intake >= epdValues.Semen_dry_matter_intake.min : true) &&
            (epdValues.Semen_dry_matter_intake?.max ? product.dry_matter_intake <= epdValues.Semen_dry_matter_intake.max : true) &&

            // Docility
            (epdValues.Semen_docility?.min ? product.docility >= epdValues.Semen_docility.min : true) &&
            (epdValues.Semen_docility?.max ? product.docility <= epdValues.Semen_docility.max : true) &&

            // Foot angle
            (epdValues.Semen_foot_angle?.min ? product.foot_angle >= epdValues.Semen_foot_angle.min : true) &&
            (epdValues.Semen_foot_angle?.max ? product.foot_angle <= epdValues.Semen_foot_angle.max : true) &&

            // Heifer pregnancy
            (epdValues.Semen_heifer_pregnancy?.min ? product.heifer_pregnancy >= epdValues.Semen_heifer_pregnancy.min : true) &&
            (epdValues.Semen_heifer_pregnancy?.max ? product.heifer_pregnancy <= epdValues.Semen_heifer_pregnancy.max : true) &&

            // Calving ease maternal
            (epdValues.Semen_calving_ease_maternal?.min ? product.calving_ease_maternal >= epdValues.Semen_calving_ease_maternal.min : true) &&
            (epdValues.Semen_calving_ease_maternal?.max ? product.calving_ease_maternal <= epdValues.Semen_calving_ease_maternal.max : true) &&

            // Milk
            (epdValues.Semen_milk?.min ? product.milk >= epdValues.Semen_milk.min : true) &&
            (epdValues.Semen_milk?.max ? product.milk <= epdValues.Semen_milk.max : true) &&

            // Mature weight
            (epdValues.Semen_mature_weight?.min ? product.mature_weight >= epdValues.Semen_mature_weight.min : true) &&
            (epdValues.Semen_mature_weight?.max ? product.mature_weight <= epdValues.Semen_mature_weight.max : true) &&

            // Mature height
            (epdValues.Semen_mature_height?.min ? product.mature_height >= epdValues.Semen_mature_height.min : true) &&
            (epdValues.Semen_mature_height?.max ? product.mature_height <= epdValues.Semen_mature_height.max : true) &&

            // Carcass weight
            (epdValues.Semen_carcass_weight?.min ? product.carcass_weight >= epdValues.Semen_carcass_weight.min : true) &&
            (epdValues.Semen_carcass_weight?.max ? product.carcass_weight <= epdValues.Semen_carcass_weight.max : true) &&

            // Carcass marbling
            (epdValues.Semen_carcass_marbling?.min ? product.carcass_marbling >= epdValues.Semen_carcass_marbling.min : true) &&
            (epdValues.Semen_carcass_marbling?.max ? product.carcass_marbling <= epdValues.Semen_carcass_marbling.max : true) &&

            // Carcass ribeye
            (epdValues.Semen_carcass_ribeye?.min ? product.carcass_ribeye >= epdValues.Semen_carcass_ribeye.min : true) &&
            (epdValues.Semen_carcass_ribeye?.max ? product.carcass_ribeye <= epdValues.Semen_carcass_ribeye.max : true) &&

            // Carcass fat
            (epdValues.Semen_carcass_fat?.min ? product.carcass_fat >= epdValues.Semen_carcass_fat.min : true) &&
            (epdValues.Semen_carcass_fat?.max ? product.carcass_fat <= epdValues.Semen_carcass_fat.max : true) &&

            // F11
            (epdValues.Semen_f11?.min ? product.f11 >= epdValues.Semen_f11.min : true) &&
            (epdValues.Semen_f11?.max ? product.f11 <= epdValues.Semen_f11.max : true) &&

            // F13
            (epdValues.Semen_f13?.min ? product.f13 >= epdValues.Semen_f13.min : true) &&
            (epdValues.Semen_f13?.max ? product.f13 <= epdValues.Semen_f13.max : true) &&

            // CHS
            (epdValues.Semen_chs?.min ? product.chs >= epdValues.Semen_chs.min : true) &&
            (epdValues.Semen_chs?.max ? product.chs <= epdValues.Semen_chs.max : true) &&

            // Band3
            (epdValues.Semen_Band3?.min ? product.Band3 >= epdValues.Semen_Band3.min : true) &&
            (epdValues.Semen_Band3?.max ? product.Band3 <= epdValues.Semen_Band3.max : true);


          console.log(`Product ID: ${product.sire_uuid || product.dam_uuid}, EPD in range: ${isEpdInRange}`);
        } else if (selectedCategory === 'dams') {
          console.log(`Applying EPD filters for Dams`);
          isEpdInRange =
            // Milk yield
            (epdValues.Embryo_milk_yield?.min ? product.milk_yield >= epdValues.Embryo_milk_yield.min : true) &&
            (epdValues.Embryo_milk_yield?.max ? product.milk_yield <= epdValues.Embryo_milk_yield.max : true) &&

            // Calving interval
            (epdValues.Embryo_calving_interval?.min ? product.calving_interval >= epdValues.Embryo_calving_interval.min : true) &&
            (epdValues.Embryo_calving_interval?.max ? product.calving_interval <= epdValues.Embryo_calving_interval.max : true) &&

            // Calving ease DIR
            (epdValues.Embryo_calving_ease_DIR?.min ? product.calving_ease_dir >= epdValues.Embryo_calving_ease_DIR.min : true) &&
            (epdValues.Embryo_calving_ease_DIR?.max ? product.calving_ease_dir <= epdValues.Embryo_calving_ease_DIR.max : true) &&

            // Calving ease DTRS
            (epdValues.Embryo_calving_ease_DTRS?.min ? product.calving_ease_dtrs >= epdValues.Embryo_calving_ease_DTRS.min : true) &&
            (epdValues.Embryo_calving_ease_DTRS?.max ? product.calving_ease_dtrs <= epdValues.Embryo_calving_ease_DTRS.max : true) &&

            // Gestation length
            (epdValues.Embryo_gestation_length?.min ? product.gestation_length >= epdValues.Embryo_gestation_length.min : true) &&
            (epdValues.Embryo_gestation_length?.max ? product.gestation_length <= epdValues.Embryo_gestation_length.max : true) &&

            // Birth weight
            (epdValues.Embryo_birth_weight?.min ? product.birth_weight >= epdValues.Embryo_birth_weight.min : true) &&
            (epdValues.Embryo_birth_weight?.max ? product.birth_weight <= epdValues.Embryo_birth_weight.max : true) &&

            // 200 day weight
            (epdValues.Embryo_200_day_weight?.min ? product.day_200_weight >= epdValues.Embryo_200_day_weight.min : true) &&
            (epdValues.Embryo_200_day_weight?.max ? product.day_200_weight <= epdValues.Embryo_200_day_weight.max : true) &&

            // 400 day weight
            (epdValues.Embryo_400_day_weight?.min ? product.day_400_weight >= epdValues.Embryo_400_day_weight.min : true) &&
            (epdValues.Embryo_400_day_weight?.max ? product.day_400_weight <= epdValues.Embryo_400_day_weight.max : true) &&

            // 600 day weight
            (epdValues.Embryo_600_day_weight?.min ? product.day_600_weight >= epdValues.Embryo_600_day_weight.min : true) &&
            (epdValues.Embryo_600_day_weight?.max ? product.day_600_weight <= epdValues.Embryo_600_day_weight.max : true) &&

            // Maternal cow weight
            (epdValues.Embryo_maternal_cow_weight?.min ? product.maternal_cow_weight >= epdValues.Embryo_maternal_cow_weight.min : true) &&
            (epdValues.Embryo_maternal_cow_weight?.max ? product.maternal_cow_weight <= epdValues.Embryo_maternal_cow_weight.max : true) &&

            // 200 day milk
            (epdValues.Embryo_200_day_milk?.min ? product.day_200_milk >= epdValues.Embryo_200_day_milk.min : true) &&
            (epdValues.Embryo_200_day_milk?.max ? product.day_200_milk <= epdValues.Embryo_200_day_milk.max : true) &&

            // Scrotal size
            (epdValues.Embryo_scrotal_size?.min ? product.scrotal_size >= epdValues.Embryo_scrotal_size.min : true) &&
            (epdValues.Embryo_scrotal_size?.max ? product.scrotal_size <= epdValues.Embryo_scrotal_size.max : true) &&

            // Carcase weight
            (epdValues.Embryo_carcase_weight?.min ? product.carcase_weight >= epdValues.Embryo_carcase_weight.min : true) &&
            (epdValues.Embryo_carcase_weight?.max ? product.carcase_weight <= epdValues.Embryo_carcase_weight.max : true) &&

            // Eye muscle area
            (epdValues.Embryo_eye_muscle_area?.min ? product.eye_muscle_area >= epdValues.Embryo_eye_muscle_area.min : true) &&
            (epdValues.Embryo_eye_muscle_area?.max ? product.eye_muscle_area <= epdValues.Embryo_eye_muscle_area.max : true) &&

            // Rib fat
            (epdValues.Embryo_rib_fat?.min ? product.rib_fat >= epdValues.Embryo_rib_fat.min : true) &&
            (epdValues.Embryo_rib_fat?.max ? product.rib_fat <= epdValues.Embryo_rib_fat.max : true) &&

            // Retail beef yield
            (epdValues.Embryo_retail_beef_yield?.min ? product.retail_beef_yield >= epdValues.Embryo_retail_beef_yield.min : true) &&
            (epdValues.Embryo_retail_beef_yield?.max ? product.retail_beef_yield <= epdValues.Embryo_retail_beef_yield.max : true) &&

            // IMF
            (epdValues.Embryo_IMF.min ? product?.IMF >= epdValues.Embryo_IMF.min : true) &&
            (epdValues.Embryo_IMF.max ? product?.IMF <= epdValues.Embryo_IMF.max : true);


          console.log(`Product ID: ${product.dam_uuid}, EPD in range: ${isEpdInRange}`);
        }

        // Logging individual filter results
        console.log(`Product ID: ${product.sire_uuid || product.dam_uuid}, Age in range: ${isAgeInRange}, Location in range: ${isLocationInRange}`);

        return isAgeInRange && isLocationInRange && isEpdInRange;
      });

      console.log("Filtered data:", filteredData);
      setProducts(filteredData);
    }
  };

  useEffect(() => {
    fetchUserZip();
  }, []);

  useEffect(() => {
    if (userZip) fetchData();
  }, [userZip, selectedCategory, sliderValue, breed, ageRange, priceRange, color]);

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const handleFeatureToggle = (feature) => {
    setSelectedFeatures((prevState) => ({
      ...prevState,
      [feature]: !prevState[feature],
    }));
  };

  const applyFilters = () => {
    fetchData();
  };

  const resetFilters = () => {
    setBreed("all");
    setAgeRange({ min: "", max: "" });
    setPriceRange({ min: "", max: "" });


    setColor("all");
    fetchData();
  };

  const resetFilters1 = () => {
    setEpdValues({
      Semen_calving_ease_direct: { min: "", max: "" },
      Semen_scrotal_circumference: { min: "", max: "" },
      Semen_birth_weight: { min: "", max: "" },
      Semen_weaning_weight: { min: "", max: "" },
      Semen_yearling_weight: { min: "", max: "" },
      Semen_dry_matter_intake: { min: "", max: "" },
      Semen_docility: { min: "", max: "" },
      Semen_foot_angle: { min: "", max: "" },
      Semen_heifer_pregnancy: { min: "", max: "" },
      Semen_calving_ease_maternal: { min: "", max: "" },
      Semen_milk: { min: "", max: "" },
      Semen_mature_weight: { min: "", max: "" },
      Semen_mature_height: { min: "", max: "" },
      Semen_carcass_weight: { min: "", max: "" },
      Semen_carcass_marbling: { min: "", max: "" },
      Semen_carcass_ribeye: { min: "", max: "" },
      Semen_carcass_fat: { min: "", max: "" },
      Semen_f11: { min: "", max: "" },
      Semen_f13: { min: "", max: "" },
      Semen_chs: { min: "", max: "" },
      Semen_Band3: { min: "", max: "" },

      Embryo_milk_yield: { min: "", max: "" },
      Embryo_calving_interval: { min: "", max: "" },
      Embryo_calving_ease_DIR: { min: "", max: "" },
      Embryo_calving_ease_DTRS: { min: "", max: "" },
      Embryo_gestation_length: { min: "", max: "" },
      Embryo_birth_weight: { min: "", max: "" },
      Embryo_200_day_weight: { min: "", max: "" },
      Embryo_400_day_weight: { min: "", max: "" },
      Embryo_600_day_weight: { min: "", max: "" },
      Embryo_maternal_cow_weight: { min: "", max: "" },
      Embryo_200_day_milk: { min: "", max: "" },
      Embryo_scrotal_size: { min: "", max: "" },
      Embryo_carcass_weight: { min: "", max: "" },
      Embryo_eye_muscle_area: { min: "", max: "" },
      Embryo_rib_fat: { min: "", max: "" },
      Embryo_retail_beef_yield: { min: "", max: "" },
      Embryo_IMF: { min: "", max: "" },
    });
    fetchData();
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedFeatures(category === 'sires' ? defaultSireFeatures : defaultDamFeatures);
  };

  const [dropdowns, setDropdowns] = useState({
    features: true,
    physical: true,
    location: true,
    epd: true,
  });

  const toggleDropdown = (section) => {
    setDropdowns((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };



  return (
    <>
      {/* Header */}
      <Header />

      {/* Main Marketplace Container */}
      <div className="marketplace-container">
        {/* Sidebar */}
        <button
    className="category-toggle sidebar-toggle"
    onClick={toggleSidebar}
    style={sidebarToggleStyle}
  >
    {sidebarVisible ? (
      'HIDE FILTERS'
    ) : (
      <img src={toggles} width="30px" height='30px'/>
    )}
  </button>

        {sidebarVisible && (
          <aside className="filter-sidebar" z-index="9999">
            {/* Sires/Embryos Toggle */}


            <div className="category-toggle">
              <button
                className={`category-button ${selectedCategory === 'sires' ? 'active' : ''}`}
                onClick={() => handleCategoryChange('sires')}
              >
                SEMEN
              </button>
              <button
                className={`category-button ${selectedCategory === 'dams' ? 'active' : ''}`}
                onClick={() => handleCategoryChange('dams')}
              >
                EMBRYOS
              </button>
            </div>

            {/* Features to Display */}
            <div className="filter-section">
              <div className="filter-header" onClick={() => toggleDropdown('features')}>
                <h3>Features</h3>
                <span>{dropdowns.features ? '▼' : '▲'}</span>
              </div>
              {dropdowns.features && (
                <div className="dropdown-content">
                  <ul>
                    {selectedCategory === 'sires' ? (
                      <>
                        <li><input type="checkbox" id="bullced" onChange={() => handleFeatureToggle('bullced')} checked={selectedFeatures.bullced} /><label htmlFor="bullced">Calving Ease Direct</label></li>
                        <li><input type="checkbox" id="bullbw" onChange={() => handleFeatureToggle('bullbw')} checked={selectedFeatures.bullbw} /><label htmlFor="bullbw">Birth Weight</label></li>
                        <li><input type="checkbox" id="bullww" onChange={() => handleFeatureToggle('bullww')} checked={selectedFeatures.bullww} /><label htmlFor="bullww">Weaning Weight</label></li>
                        <li><input type="checkbox" id="bullyw" onChange={() => handleFeatureToggle('bullyw')} checked={selectedFeatures.bullyw} /><label htmlFor="bullyw">Yearling Weight</label></li>
                      </>
                    ) : (
                      <>
                        <li><input type="checkbox" id="milkYield" onChange={() => handleFeatureToggle('milkYield')} checked={selectedFeatures.milkYield} /><label htmlFor="milkYield">Milk Yield</label></li>
                        <li><input type="checkbox" id="calvingInterval" onChange={() => handleFeatureToggle('calvingInterval')} checked={selectedFeatures.calvingInterval} /><label htmlFor="calvingInterval">Calving Interval</label></li>
                        <li><input type="checkbox" id="ced" onChange={() => handleFeatureToggle('ced')} checked={selectedFeatures.ced} /><label htmlFor="ced">Calving Ease Direct</label></li>
                        <li><input type="checkbox" id="bw" onChange={() => handleFeatureToggle('bw')} checked={selectedFeatures.bw} /><label htmlFor="bw">Birth Weight</label></li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </div>


            {/* Physical Section */}
            <div className="filter-section">
              <div className="filter-header" onClick={() => toggleDropdown('physical')}>
                <h3>Physical</h3>
                <span>{dropdowns.physical ? '▼' : '▲'}</span>
              </div>
              {dropdowns.physical && (
                <div className="dropdown-content">
                  <div className="epd-row">
                    <label className="epd-label">Breed</label>
                    <select value={breed} onChange={(e) => setBreed(e.target.value)}>
                      <option value="all">All Breeds</option>
                      <option value="Angus">Angus</option>
                      <option value="Hereford">Hereford</option>
                      <option value="Holstein">Holstein</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Limousin">Limousin</option>
                      <option value="Simmental">Simmental</option>
                      <option value="Charolais">Charolais</option>
                      <option value="Brahman">Brahman</option>
                      <option value="Shorthorn">Shorthorn</option>
                      <option value="Gelbvieh">Gelbvieh</option>
                      <option value="Brangus">Brangus</option>
                      <option value="Santa Gertrudis">Santa Gertrudis</option>
                      <option value="Beefmaster">Beefmaster</option>
                      <option value="Longhorn">Longhorn</option>
                      <option value="Red Angus">Red Angus</option>
                      <option value="Ayrshire">Ayrshire</option>
                      <option value="Brown Swiss">Brown Swiss</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Dexter">Dexter</option>
                      <option value="Belgian Blue">Belgian Blue</option>
                      <option value="Maine Anjou">Maine Anjou</option>
                      <option value="Chianina">Chianina</option>
                      <option value="Salers">Salers</option>
                      <option value="Piedmontese">Piedmontese</option>
                      <option value="Highland">Highland</option>
                      <option value="Blonde d'Aquitaine">Blonde d'Aquitaine</option>
                      <option value="South Devon">South Devon</option>
                      <option value="Tarentaise">Tarentaise</option>
                      <option value="Ankole-Watusi">Ankole-Watusi</option>
                      <option value="Murray Grey">Murray Grey</option>
                      <option value="Normande">Normande</option>
                      <option value="Bazadaise">Bazadaise</option>
                      <option value="Wagyu">Wagyu</option>
                      <option value="Red Poll">Red Poll</option>
                      <option value="Devon">Devon</option>
                      <option value="Lincoln Red">Lincoln Red</option>
                      <option value="Romagnola">Romagnola</option>
                      <option value="Parthenais">Parthenais</option>
                      <option value="Florida Cracker">Florida Cracker</option>
                      <option value="Galloway">Galloway</option>
                      <option value="Corriente">Corriente</option>
                      <option value="Santa Cruz">Santa Cruz</option>
                      <option value="Hays Converter">Hays Converter</option>
                      <option value="Beefalo">Beefalo</option>
                      <option value="Afrikaner">Afrikaner</option>
                      <option value="Nguni">Nguni</option>
                      <option value="Pinzgauer">Pinzgauer</option>
                      <option value="Tuli">Tuli</option>
                    </select>
                  </div>

                  <div className="epd-row">
                    <label className="epd-label">Age</label>
                    <div className="min-max1">
                      <input type="text" placeholder="Min" value={ageRange.min} onChange={(e) => setAgeRange({ ...ageRange, min: e.target.value })} />
                      <input type="text" placeholder="Max" value={ageRange.max} onChange={(e) => setAgeRange({ ...ageRange, max: e.target.value })} />
                    </div>
                  </div>

                  <div className="epd-row">
                    <label className="epd-label">Price</label>
                    <div className="min-max1">
                      <input type="text" placeholder="Min" value={priceRange.min} onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })} />
                      <input type="text" placeholder="Max" value={priceRange.max} onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })} />
                    </div>
                  </div>

                  <div className="epd-row">
                    <label className="epd-label">Color</label>
                    <select value={color} onChange={(e) => setColor(e.target.value)}>
                      <option value="all">All Colors</option>
                      <option value="Black">Black</option>
                      <option value="Brown">Brown</option>
                      <option value="White">White</option>
                      <option value="Red">Red</option>
                      <option value="Yellow">Yellow</option>
                      <option value="Gray">Gray</option>
                      <option value="Dun">Dun</option>
                      <option value="Roan">Roan</option>
                      <option value="Brindle">Brindle</option>
                      <option value="Spotted">Spotted</option>
                      <option value="Piebald">Piebald</option>
                      <option value="Pinto">Pinto</option>
                      <option value="Blue">Blue</option>
                      <option value="Speckled">Speckled</option>
                      <option value="Cream">Cream</option>
                      <option value="Mahogany">Mahogany</option>
                      <option value="Chestnut">Chestnut</option>
                      <option value="Fawn">Fawn</option>
                      <option value="Golden">Golden</option>
                      <option value="Straw">Straw</option>
                      <option value="Tan">Tan</option>
                      <option value="Silver">Silver</option>
                      <option value="Ivory">Ivory</option>
                      <option value="Orange">Orange</option>
                      <option value="Amber">Amber</option>
                      {/* Add more color options as needed */}
                    </select>
                  </div>




                  <div className="button-wrapper">
                    <button className="reset-button" onClick={resetFilters}>Reset</button>
                  </div>
                </div>
              )}
            </div>

            {/* Location Section */}
            <div className="filter-section">
              <div className="filter-header" onClick={() => toggleDropdown('location')}>
                <h3>Location</h3>
                <span>{dropdowns.location ? '▼' : '▲'}</span>
              </div>
              {dropdowns.location && (
                <div className="dropdown-content">
                  <div className="location-slider">
                    <input
                      type="range"
                      min="0"
                      max="5600"
                      value={sliderValue}
                      onChange={handleSliderChange}
                    />
                    <div className="slider-value">{sliderValue} miles</div>
                  </div>

                </div>
              )}
            </div>

            {/* EPD Values */}
            <div className="filter-section">
              <div className="filter-header" onClick={() => toggleDropdown('epd')}>
                <h3>EPD Values</h3>
                <span>{dropdowns.epd ? '▼' : '▲'}</span>
              </div>
              {dropdowns.epd && (
                <div className="dropdown-content">
                  {selectedCategory === 'sires' ? (
                    <>
                      <div className="epd-row">
                        <label className="epd-label">Calving Ease Direct</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_calving_ease_direct.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_calving_ease_direct: { ...prev.Semen_calving_ease_direct, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_calving_ease_direct.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_calving_ease_direct: { ...prev.Semen_calving_ease_direct, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Scrotal Circumference</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_scrotal_circumference.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_scrotal_circumference: { ...prev.Semen_scrotal_circumference, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_scrotal_circumference.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_scrotal_circumference: { ...prev.Semen_scrotal_circumference, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label"> Birth Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_birth_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_birth_weight: { ...prev.Semen_birth_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_birth_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_birth_weight: { ...prev.Semen_birth_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Weaning Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_weaning_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_weaning_weight: { ...prev.Semen_weaning_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_weaning_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_weaning_weight: { ...prev.Semen_weaning_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Yearling Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_yearling_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_yearling_weight: { ...prev.Semen_yearling_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_yearling_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_yearling_weight: { ...prev.Semen_yearling_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Dry Matter Intake</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_dry_matter_intake.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_dry_matter_intake: { ...prev.Semen_dry_matter_intake, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_dry_matter_intake.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_dry_matter_intake: { ...prev.Semen_dry_matter_intake, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Docility</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_docility.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_docility: { ...prev.Semen_docility, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_docility.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_docility: { ...prev.Semen_docility, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Foot Angle</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_foot_angle.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_foot_angle: { ...prev.Semen_foot_angle, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_foot_angle.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_foot_angle: { ...prev.Semen_foot_angle, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Heifer Pregnancy</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_heifer_pregnancy.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_heifer_pregnancy: { ...prev.Semen_heifer_pregnancy, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_heifer_pregnancy.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_heifer_pregnancy: { ...prev.Semen_heifer_pregnancy, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Calving Ease Maternal</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_calving_ease_maternal.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_calving_ease_maternal: { ...prev.Semen_calving_ease_maternal, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_calving_ease_maternal.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_calving_ease_maternal: { ...prev.Semen_calving_ease_maternal, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Milk</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_milk.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_milk: { ...prev.Semen_milk, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_milk.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_milk: { ...prev.Semen_milk, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Mature Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_mature_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_mature_weight: { ...prev.Semen_mature_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_mature_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_mature_weight: { ...prev.Semen_mature_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Mature Height</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_mature_height.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_mature_height: { ...prev.Semen_mature_height, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_mature_height.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_mature_height: { ...prev.Semen_mature_height, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Carcass Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_carcass_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_carcass_weight: { ...prev.Semen_carcass_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_carcass_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_carcass_weight: { ...prev.Semen_carcass_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Carcass Marbling</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_carcass_marbling.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_carcass_marbling: { ...prev.Semen_carcass_marbling, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_carcass_marbling.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_carcass_marbling: { ...prev.Semen_carcass_marbling, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Carcass Ribeye</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_carcass_ribeye.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_carcass_ribeye: { ...prev.Semen_carcass_ribeye, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_carcass_ribeye.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_carcass_ribeye: { ...prev.Semen_carcass_ribeye, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Carcass Fat</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_carcass_fat.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_carcass_fat: { ...prev.Semen_carcass_fat, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_carcass_fat.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_carcass_fat: { ...prev.Semen_carcass_fat, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">F11</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_f11.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_f11: { ...prev.Semen_f11, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_f11.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_f11: { ...prev.Semen_f11, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">F13</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_f13.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_f13: { ...prev.Semen_f13, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_f13.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_f13: { ...prev.Semen_f13, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">CHS</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_chs.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_chs: { ...prev.Semen_chs, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_chs.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_chs: { ...prev.Semen_chs, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Band3</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Semen_Band3.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_Band3: { ...prev.Semen_Band3, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Semen_Band3.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Semen_Band3: { ...prev.Semen_Band3, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="epd-row">
                        <label className="epd-label">Milk Yield</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_milk_yield.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_milk_yield: { ...prev.Embryo_milk_yield, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_milk_yield.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_milk_yield: { ...prev.Embryo_milk_yield, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Calving Interval</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_calving_interval.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_calving_interval: { ...prev.Embryo_calving_interval, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_calving_interval.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_calving_interval: { ...prev.Embryo_calving_interval, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Calving Ease DIR</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_calving_ease_DIR.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_calving_ease_DIR: { ...prev.Embryo_calving_ease_DIR, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_calving_ease_DIR.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_calving_ease_DIR: { ...prev.Embryo_calving_ease_DIR, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Embryo Calving Ease DTRS</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_calving_ease_DTRS.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_calving_ease_DTRS: { ...prev.Embryo_calving_ease_DTRS, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_calving_ease_DTRS.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_calving_ease_DTRS: { ...prev.Embryo_calving_ease_DTRS, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Gestation Length</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_gestation_length.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_gestation_length: { ...prev.Embryo_gestation_length, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_gestation_length.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_gestation_length: { ...prev.Embryo_gestation_length, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Birth Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_birth_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_birth_weight: { ...prev.Embryo_birth_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_birth_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_birth_weight: { ...prev.Embryo_birth_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">200 Day Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_200_day_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_200_day_weight: { ...prev.Embryo_200_day_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_200_day_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_200_day_weight: { ...prev.Embryo_200_day_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">400 Day Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_400_day_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_400_day_weight: { ...prev.Embryo_400_day_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_400_day_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_400_day_weight: { ...prev.Embryo_400_day_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>


                      <div className="epd-row">
                        <label className="epd-label">600 Day Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_600_day_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_600_day_weight: { ...prev.Embryo_600_day_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_600_day_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_600_day_weight: { ...prev.Embryo_600_day_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Maternal Cow Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_maternal_cow_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_maternal_cow_weight: { ...prev.Embryo_maternal_cow_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_maternal_cow_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_maternal_cow_weight: { ...prev.Embryo_maternal_cow_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">200 Day Milk</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_200_day_milk.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_200_day_milk: { ...prev.Embryo_200_day_milk, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_200_day_milk.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_200_day_milk: { ...prev.Embryo_200_day_milk, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Scrotal Size</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_scrotal_size.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_scrotal_size: { ...prev.Embryo_scrotal_size, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_scrotal_size.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_scrotal_size: { ...prev.Embryo_scrotal_size, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Scrotal Size</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_scrotal_size.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_scrotal_size: { ...prev.Embryo_scrotal_size, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_scrotal_size.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_scrotal_size: { ...prev.Embryo_scrotal_size, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Carcass Weight</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_carcass_weight.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_carcass_weight: { ...prev.Embryo_carcass_weight, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_carcass_weight.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_carcass_weight: { ...prev.Embryo_carcass_weight, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Eye Muscle Area</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_eye_muscle_area.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_eye_muscle_area: { ...prev.Embryo_eye_muscle_area, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_eye_muscle_area.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_eye_muscle_area: { ...prev.Embryo_eye_muscle_area, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Rib Fat</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_rib_fat.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_rib_fat: { ...prev.Embryo_rib_fat, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_rib_fat.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_rib_fat: { ...prev.Embryo_rib_fat, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">Retail Beef Yield</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_retail_beef_yield.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_retail_beef_yield: { ...prev.Embryo_retail_beef_yield, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_retail_beef_yield.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_retail_beef_yield: { ...prev.Embryo_retail_beef_yield, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="epd-row">
                        <label className="epd-label">IMF</label>
                        <div className="min-max1">
                          <input
                            type="text"
                            placeholder="Min"
                            value={epdValues.Embryo_IMF.min}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_IMF: { ...prev.Embryo_IMF, min: e.target.value },
                              }))
                            }
                          />
                          <input
                            type="text"
                            placeholder="Max"
                            value={epdValues.Embryo_IMF.max}
                            onChange={(e) =>
                              setEpdValues((prev) => ({
                                ...prev,
                                Embryo_IMF: { ...prev.Embryo_IMF, max: e.target.value },
                              }))
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="button-wrapper">
                    <button className="apply-button" onClick={applyFilters}>Apply</button>
                    <button className="reset-button" onClick={resetFilters1}>Reset</button>
                  </div>
                </div>
              )}
            </div>
          </aside>
        )}

        {/* Product Grid */}
        <section className="products-grid">
          {products.map((product) => (
            <SireCard
              key={product.sire_uuid || product.dam_uuid}
              id={product.sire_uuid || product.dam_uuid}
              imageUrl={product.picture_aws_link1} // Display first image URL
              name={product.name}
              breed={product.breed}
              location={`${product.city || 'Unknown'}, ${product.state || ''}`.trim()}
              price={product.price}
              rating={product.rating} // Static rating for now; can be dynamic if available
              selectedFeatures={selectedFeatures}
              product={product} // Pass the entire product object

            />
          ))}
        </section>
      </div>
    </>
  );
};

export default Marketplace;