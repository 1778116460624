import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import supabase from './supabaseClient';
import './Reviews.css';
import Header from './Header';

const ReviewsPage = () => {
  const { user_uuid } = useParams();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [products, setProducts] = useState([]);
  const [averageRating, setAverageRating] = useState(null);
  const currentUserId = localStorage.getItem('user_uuid');  


  useEffect(() => {
    const fetchUserInfo = async () => {
      const { data: user, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('user_uuid', user_uuid)
        .single();

      if (userError) {
        console.error('Error fetching user:', userError);
      } else {
        setUserInfo(user);
      }
    };


    const fetchUserReviews = async () => {
      const { data: reviews, error: reviewsError } = await supabase
        .from('reviews')
        .select('review, stars, reviewer_user_uuid')
        .eq('reviewed_user_uuid', user_uuid);

      if (reviewsError) {
        console.error('Error fetching reviews:', reviewsError);
      } else {
        const reviewerIds = reviews.map((review) => review.reviewer_user_uuid);

        // Fetch reviewer names based on their UUIDs
        const { data: reviewers, error: reviewersError } = await supabase
          .from('users')
          .select('user_uuid, name')
          .in('user_uuid', reviewerIds);

        if (reviewersError) {
          console.error('Error fetching reviewers:', reviewersError);
        } else {
          // Map reviewer names to reviews
          const reviewsWithNames = reviews.map((review) => {
            const reviewer = reviewers.find((user) => user.user_uuid === review.reviewer_user_uuid);
            return {
              ...review,
              reviewerName: reviewer ? reviewer.name : 'Anonymous',
            };
          });

          setReviews(reviewsWithNames);

          // Calculate the average rating
          const totalStars = reviews.reduce((acc, review) => acc + review.stars, 0);
          setAverageRating(reviews.length > 0 ? (totalStars / reviews.length).toFixed(1) : NaN);
        }
      }
    };

    const fetchUserProducts = async () => {
      const { data: products, error: productsError } = await supabase
        .from('sires')
        .select('*')
        .eq('user_uuid', user_uuid);

      if (productsError) {
        console.error('Error fetching products:', productsError);
      } else {
        setProducts(products);
      }
    };

    fetchUserInfo();
    fetchUserReviews();
    fetchUserProducts();
  }, [user_uuid]);



  const handleMessageSeller = async () => {
    if (!userInfo) return;

    const messageText = `Hi! I am interested in your products.`;
  
    try {
      const { data: existingChat, error: checkError } = await supabase
        .from('messaging')
        .select('message_uuid')
        .or(`and(sender_uuid.eq.${currentUserId},receiver_uuid.eq.${user_uuid}),and(sender_uuid.eq.${user_uuid},receiver_uuid.eq.${currentUserId})`)
        .single();

      if (checkError && checkError.code !== 'PGRST116') {
        console.error('Error checking existing chat:', checkError);
        return;
      }

      if (!existingChat) {
        const { error: insertError } = await supabase.from('messaging').insert([
          {
            message_uuid: crypto.randomUUID(),
            sender_uuid: currentUserId,
            receiver_uuid: user_uuid,
            message: messageText,
            timestamp: new Date().toISOString(),
          },
        ]);

        if (insertError) {
          console.error('Error creating new chat:', insertError);
          return;
        }
      }

      // Navigate to the messaging page with the seller's user UUID
      navigate(`/messages/${user_uuid}`);
    } catch (error) {
      console.error("An error occurred while setting up the chat:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="reviews-page2">
        {/* Seller Profile Section */}
        {userInfo && (
          <div className="seller-profile2">
            <div className="profile-img2">
              <img src={userInfo.image || 'https://placehold.co/600x400'} alt="Seller Profile" className="profile-img-placeholder2" />
            </div>
            <div className="profile-details2">
              <h2>{userInfo.name}</h2>
              <p><strong>Farm Name:</strong> {userInfo.farm_name}</p>
              <p><strong>Location:</strong> {userInfo.city}, {userInfo.state}</p>
              <p><strong>Website:</strong> <a href={userInfo.farm_website} target="_blank" rel="noopener noreferrer">{userInfo.farm_website}</a></p>
            </div>
          </div>
        )}

        {/* Products Section */}
        <div className="products-section2">
          <h3>Products by {userInfo ? userInfo.name : "Seller"}</h3>
          <div className="products-list2">
            {products.map((product, index) => (
              <div className="product-card2" key={index}>
                <Link to={`/product/${product.sire_uuid}`} className="product-link2">
                  <img src={product.picture_aws_link1 || 'https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg300'} alt={product.name} className="product-image2" />
                  <div className="product-info2">
                    <h4>{product.name}</h4>
                    <p><strong>Breed:</strong> {product.breed}</p>
                    <p><strong>Price:</strong> ${product.price}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <button className="message-seller-button2" onClick={handleMessageSeller}>Message Seller</button>
        </div>

        {/* Reviews Section */}
        {reviews.length > 0 && (
          <div className="reviews-section2">
            <h3>Reviews</h3>
            <div className="overall-rating2">
              <span>Overall Rating: {isNaN(averageRating) ? 'No reviews' : averageRating} out of 5</span>
            </div>
            <div className="reviews-list2">
              {reviews.map((review, index) => (
                <div className="review-card2" key={index}>
                  <div className="review-rating2">
                    <span>Rating: {review.stars} / 5</span>
                  </div>
                  <div className="review-comment2">
                    <p>{review.review}</p>
                  </div>
                  <div className="reviewer2">
                    <span>— {review.reviewerName}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReviewsPage;
