import React from 'react';
import Header from './Header';
import './TeamPage.css';
import asray from '../assets/asray.jpeg';
import justin from '../assets/justin.jpeg';
import arjun from '../assets/arjun.jpeg';
import hannah from '../assets/hannah.jpeg';
import link from '../assets/link.webp'; // Placeholder LinkedIn icon

const teamMembers = [
  {
    name: "Asray Gopa",
    role: "Founding Partner",
    description: "Bio in Progress.",
    image: asray,
    linkedIn: "https://www.linkedin.com/in/asrayg/", // Replace with actual LinkedIn profile link
    email: "asray@iastate.edu"
  },
  {
    name: "Arjun Patel",
    role: "Founding Partner",
    description: "Bio in Progress.",
    image: arjun,
    linkedIn: "https://www.linkedin.com/in/arjunpatel80/", // Replace with actual LinkedIn profile link
    email: "anpatel@iastate.edu"
  },
  {
    name: "Hannah Everhart",
    role: "Founding Partner",
    description: "Bio in Progress.",
    image: hannah,
    linkedIn: "https://www.linkedin.com/in/hannaheverhartm/", // Replace with actual LinkedIn profile link
    email: "hme@iastate.edu"
  },
  {
    name: "Justin Moran",
    role: "Founding Partner",
    description: "Bio in Progress.",
    image: justin,
    linkedIn: "https://www.linkedin.com/in/justin-moran-629a311a3/", // Replace with actual LinkedIn profile link
    email: "jmoran0@iastate.edu"
  }
];

export default function TeamPage() {
  return (
    <>
      <Header />
      <div className="team-page-container">
        <h1>Meet Our Team</h1>
        <div className="team-members">
          {teamMembers.map((member, index) => (
            <div className="team-member" key={index}>
              <img src={member.image} alt={member.name} className="team-member-image" />
              <h2>{member.name}</h2>
              <h3>{member.role}</h3>
              <p>{member.description}</p>
              <div className="contact-info">
                <a href={member.linkedIn} target="_blank" rel="noopener noreferrer">
                  <img src={link} alt="LinkedIn" className="linkedin-icon" />
                </a>
                <p>
                  <a href={`mailto:${member.email}`}>{member.email}</a>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
