// Import the JSON file directly
import zipLatLngData from './csvjson.json';


const toRadians = (degrees) => (degrees * Math.PI) / 180;
const EARTH_RADIUS_MILES = 3958.8;

const calculateDistance = (lat1, lng1, lat2, lng2) => {
  const dLat = toRadians(lat2 - lat1);
  const dLng = toRadians(lng2 - lng1);

  const a = Math.sin(dLat / 2) ** 2 +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLng / 2) ** 2;

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return EARTH_RADIUS_MILES * c;
};

export const isWithinDistance = (userZip, productZip, maxDistance) => {

  const userLocation = zipLatLngData.find((zip) => zip.zip_code === parseInt(userZip));
  const productLocation = zipLatLngData.find((zip) => zip.zip_code === parseInt(productZip));

  if (!userLocation) {
    console.warn(`User location not found for ZIP: ${userZip}`);
    return false;
  }

  if (!productLocation) {
    console.warn(`Product location not found for ZIP: ${productZip}`);
    return false;
  }


  const distance = calculateDistance(
    parseFloat(userLocation.latitude),
    parseFloat(userLocation.longitude),
    parseFloat(productLocation.latitude),
    parseFloat(productLocation.longitude)
  );

  console.log(`Calculated distance: ${distance} miles`);

  const isWithinRange = distance <= maxDistance;
  console.log(`Is within distance: ${isWithinRange}`);

  return isWithinRange;
};
