import React, { useEffect } from 'react';

import Header from './Header'; 
import './LandingPage.css'; 
import Hereford from '../assets/Hereford.jpg'  
import Quality from '../assets/Quality.png'
import Easy from '../assets/Easy.png'
import Verified from '../assets/Verified.png'
import Instagram from '../assets/instagram.webp'
import Link from '../assets/link.webp'
import Facebook from '../assets/facebook.webp'
import tiktok from '../assets/tiktok.webp'



const LandingPage = () => {
  useEffect(() => {
    // Preload the hero image
    const preloadImage = new Image();
    preloadImage.src = Hereford;
  }, []);

  return (
    <div className="landing-container">
      <Header />
      <section className="hero">
        <img src={Hereford} alt="Cows in field" className="hero-image" />
        <div className="hero-text">
          <h2>Sell and Buy Semen and Embryos Faster and Better</h2>
        </div>
      </section>

      <section className="features">
        <div className="feature-item">
          <img src={Quality} alt="Quality Genetics" />
        </div>
        <div className="feature-item">
          <img src={Verified} alt="Verified Sellers" />
        </div>
        <div className="feature-item">
          <img src={Easy} alt="Easy Transactions" />
        </div>
      </section>

      <footer className="footer">
    <div className="footer-left">
      <h2>Why Choose Us</h2>
      <div className="social-icons">
        <a href="https://www.instagram.com/herdmate.app/"><img src={Instagram} alt="Instagram" /></a>
        <a href="https://www.linkedin.com/company/103411763/"><img src={Link} alt="LinkedIn" /></a>
        <a href="https://www.facebook.com/profile.php?id=61567029250664"><img src={Facebook} alt="Facebook" /></a>
        <a href="#"><img src={tiktok} alt="TikTok" /></a>
      </div>
    </div>
    
    <div className="footer-right">
          <p>Herdmate is your trusted online marketplace for sourcing and listing premium semen and embryos.</p>
          <ul className="footer-links">
            <li><a href="/team">Our Team</a></li>
            <li><a href="/TnC">Terms & Conditions</a></li>
            <li><a href="https://herdmate-blog.blogspot.com/">Our Blog</a></li>
          </ul>
        </div>


    


  </footer>
  <div className="footer-bottom">
      <p>&copy; 2024 HerdMate. All rights reserved.</p>
    </div>


    </div>
  );
};

export default LandingPage;
