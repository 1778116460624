import React, { useEffect, useState } from 'react';
import supabase from './supabaseClient';
import './CurrentPastOrders.css';

const CurrentPastOrders = () => {
  const [orders, setOrders] = useState([]);
  const userId = localStorage.getItem('user_uuid'); // Get logged-in user UUID

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Fetch batches of done orders grouped by batch_uuid
        const { data: ordersData, error } = await supabase
          .from('done_orders')
          .select('batch_uuid, timestamp, buyer_address, sire_id, dam_id, sire_quantity, dam_quantity, total_price, order_fulfilled, shipped, sires(name), dams(name)')
          .eq('user_uuid', userId)
          .order('timestamp', { ascending: false });

        if (error) {
          console.error('Error fetching orders:', error);
          return;
        }

        // Group orders by batch_uuid
        const groupedOrders = ordersData.reduce((acc, order) => {
          const batch = acc[order.batch_uuid] || [];
          batch.push(order);
          acc[order.batch_uuid] = batch;
          return acc;
        }, {});

        setOrders(Object.entries(groupedOrders)); // Convert object to array of batches
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const getStatusCircle = (status) => (
    <span className={`status-circle ${status ? 'green' : 'red'}`} />
  );

  return (
    <div className="orders-container">
      <h2>Your Orders</h2>
      <div className="orders-scrollable">
        {orders.length === 0 ? (
          <p>No past orders found.</p>
        ) : (
          orders.map(([batchUuid, batchOrders]) => {
            // Calculate total items and total amount for the batch
            const totalItems = batchOrders.reduce(
              (acc, order) => acc + (order.sire_quantity || order.dam_quantity),
              0
            );
            const totalAmount = batchOrders.reduce((acc, order) => acc + order.total_price, 0);

            // Assume all orders in a batch have the same status (for simplicity)
            const orderFulfilled = batchOrders.every((order) => order.order_fulfilled);
            const shipped = batchOrders.every((order) => order.shipped);

            return (
              <div key={batchUuid} className="order-batch">
                <h3>
                  Batch #{batchUuid} - {totalItems} item(s), Total: ${totalAmount.toFixed(2)}
                </h3>
                <p>Ordered On: {new Date(batchOrders[0]?.timestamp).toLocaleString()}</p>
                <ul>
                  {batchOrders.map((order, index) => {
                    const isSire = !!order.sire_id;
                    const productId = isSire ? order.sire_id : order.dam_id;
                    const productName = isSire ? order.sires?.name : order.dams?.name;
                    const productLink = `https://herdmate.app/product/${productId}`;

                    return (
                      <li key={index}>
                        <a href={productLink} target="_blank" rel="noopener noreferrer">
                          {productName || 'Unnamed Product'}
                        </a>{' '}
                        - Quantity: {order.sire_quantity || order.dam_quantity}, Total: ${order.total_price.toFixed(2)}
                      </li>
                    );
                  })}
                </ul>
                <div className="order-status">
                  <p>
                    Order Fulfilled: {getStatusCircle(orderFulfilled)}
                  </p>
                  <p>
                    Shipped: {getStatusCircle(shipped)}
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default CurrentPastOrders;
