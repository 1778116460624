import React, { useState, useEffect } from 'react';
import './Cart.css';
import { FaTrash } from 'react-icons/fa'; // Import Font Awesome trash icon
import Header from './Header';
import supabase from './supabaseClient';
import CurrentPastOrders from './CurrentPastOrders'; // Import the new component
import YourSales from './YourSales'; // Import the new component


import { useNavigate } from 'react-router-dom';

const CartPage = () => {
  const userId = localStorage.getItem('user_uuid');
  const navigate = useNavigate(); // Initialize navigate
  const [cartItems, setCartItems] = useState([]);
  const [address, setAddress] = useState('');
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [newAddress, setNewAddress] = useState(address);

  // Fetch cart items and user address from Supabase
  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const { data: cartData, error: cartError } = await supabase
          .from('cart_items')
          .select('*, sires(*), dams(*)')
          .eq('user_uuid', userId)
          .eq('active_order', true);
    
        if (cartError || !cartData) {
          console.error('Error fetching cart items:', cartError || 'No data returned');
          return;
        }

        localStorage.setItem('lastVisited', 'noway') 

    
        const mergedItems = mergeCartItems(cartData);
        setCartItems(mergedItems);
    
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('address, city, state, zipcode')
          .eq('user_uuid', userId)
          .single();
    
        if (userError || !userData) {
          console.error('Error fetching user address:', userError || 'No data returned');
          return;
        }
    
        const fullAddress = `${userData.address}, ${userData.city}, ${userData.state} ${userData.zipcode}`;
        setAddress(fullAddress);
      } catch (error) {
        console.error('Unexpected error fetching cart data:', error);
      }
    };
    

    fetchCartData();
  }, []);

  // Sync cartItems and address to localStorage
  useEffect(() => {
    const checkoutData = {
      address,
      cartItems,
    };
    localStorage.setItem('checkoutData', JSON.stringify(checkoutData));
  }, [cartItems, address]);

  const mergeCartItems = (items) => {
    const merged = [];
    items.forEach((item) => {
      const itemId = item.sire_id || item.dam_id;
      const existingIndex = merged.findIndex((mergedItem) => mergedItem.itemId === itemId);
  
      if (existingIndex !== -1) {
        // Update quantity and total price
        merged[existingIndex].quantity += item.sire_quantity || item.dam_quantity || 0;
        merged[existingIndex].totalPrice += item.total_price || 0;
      } else {
        // New entry with relevant details
        const mergedItem = {
          id: item.cart_item_uuid,
          itemId,
          name: item.sires?.name || item.dams?.name || 'Unknown Item',
          breed: item.sires?.breed || item.dams?.breed || 'Unknown Breed',
          location: `${item.sires?.city || item.dams?.city || 'Unknown City'}, ${item.sires?.state || item.dams?.state || 'Unknown State'}`,
          price: item.total_price / (item.sire_quantity || item.dam_quantity || 1),
          imageUrl: item.sires?.picture_aws_link1 || item.dams?.picture_aws_link1 || '/default-image.png',
          quantity: item.sire_quantity || item.dam_quantity || 1,
          totalPrice: item.total_price || 0,
          productPage: item.sires
            ? `/product/${item.sire_id}`
            : `/product/${item.dam_id}`,
        };
        merged.push(mergedItem);
      }
    });
    return merged;
  };
  

  const handleQuantityChange = async (id, amount) => {
    const updatedItems = cartItems.map((item) =>
      item.id === id
        ? {
            ...item,
            quantity: Math.max(1, item.quantity + amount),
            totalPrice: item.price * Math.max(1, item.quantity + amount),
          }
        : item
    );
    setCartItems(updatedItems);

    const updatedItem = updatedItems.find((item) => item.id === id);
    await supabase
      .from('cart_items')
      .update({
        sire_quantity: updatedItem.quantity,
        total_price: updatedItem.totalPrice,
      })
      .eq('cart_item_uuid', id);
  };

  const handleDelete = async (id) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
    await supabase.from('cart_items').delete().eq('cart_item_uuid', id);
  };

  const handleSaveAddress = () => {
    setAddress(newAddress);
    setIsAddressModalOpen(false);
  };

  const handleCheckout = async () => {
    try {
      const checkoutData = JSON.parse(localStorage.getItem('checkoutData'));
      const cartItems = checkoutData?.cartItems || [];
  
      const line_items = cartItems.map((item) => ({
        name: item.name,
        unit_amount: item.price * 100, 
        quantity: item.quantity,
      }));
  
      localStorage.setItem('lastVisited', 'cool') 
      localStorage.setItem('orderProcessed', 'nahhhhh'); // Prevent re-processing

      const response = await fetch('https://api.herdmate.app/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ line_items }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Failed to create checkout session:', errorText);
        return;
      }
      localStorage.setItem('orderProcessed', 'nahhhhh'); // Prevent re-processing

      localStorage.setItem('lastVisited', 'cool') 

  
      const result = await response.json();
      window.location.href = result.checkout_url;
    } catch (error) {
      console.error('An error occurred during checkout:', error);
    }
  };
  

  return (
    <div className="cart-page-container">
      <Header />
      <div className="cart-content">
        <div className="cart-items">
          <h2>Shopping Cart</h2>
          {cartItems.map((item) => (
            <div key={item.id} className="cart-item">
              <a href={item.productPage} target="_blank" rel="noopener noreferrer" className="cart-item-link">
                <img src={item.imageUrl} alt={item.name} className="cart-item-image" />
              </a>
              <div className="cart-item-details">
                <p className="item-name">{item.name}</p>
                <p className="item-breed">{item.breed}</p>
                <p className="item-location">{item.location}</p>
                <p className="item-price">${item.price.toFixed(2)}</p>
              </div>
              <div className="cart-item-quantity">
                <p>${item.totalPrice.toFixed(2)}</p>
                <div className="quantity-controls">
                  <button onClick={() => handleQuantityChange(item.id, -1)} disabled={item.quantity === 1}>
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button onClick={() => handleQuantityChange(item.id, 1)}>+</button>
                </div>
              </div>
              <button className="delete-button" onClick={() => handleDelete(item.id)}>
                <FaTrash />
              </button>
            </div>
          ))}
        </div>

        <div className="cart-summary">
          <div className="subtotal">
            <p>
              Subtotal ({cartItems.length} items):{' '}
              <span>${cartItems.reduce((acc, item) => acc + item.totalPrice, 0).toFixed(2)}</span>
            </p>
            <button className="checkout-button" onClick={handleCheckout}>
              Checkout
            </button>
          </div>
          <div className="shipping-address">
            <h4>Shipping address</h4>
            <p>{address}</p>
            <a href="#" onClick={() => setIsAddressModalOpen(true)} className="change-address">
              Change
            </a>
          </div>
        </div>
      </div>

      <CurrentPastOrders />
      <YourSales />


      {isAddressModalOpen && (
        <div className="address-modal">
          <div className="address-modal-content">
            <h4>Change Shipping Address</h4>
            <textarea value={newAddress} onChange={(e) => setNewAddress(e.target.value)} rows="3" />
            <button onClick={() => setIsAddressModalOpen(false)} className="modal-close">
              Close
            </button>
            <button onClick={handleSaveAddress} className="modal-save">
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
