import React, { useEffect, useState } from 'react';
import supabase from './supabaseClient';
import './YourSales.css';

const YourSales = () => {
  const [sales, setSales] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const userId = localStorage.getItem('user_uuid'); // Get logged-in seller UUID

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        // Fetch sales data for the logged-in seller
        const { data: salesData, error } = await supabase
          .from('done_orders')
          .select('batch_uuid, timestamp, total_price, buyer_address, user_uuid, sires(name), dams(name), sire_quantity, dam_quantity, seller_paid, shipped, users(email, phone_number)')
          .eq('seller uuid', userId) // Only fetch sales where the logged-in user is the seller
          .order('timestamp', { ascending: false });

        if (error) {
          console.error('Error fetching sales:', error);
          return;
        }

        // Group sales by batch_uuid
        const groupedSales = salesData.reduce((acc, sale) => {
          const batch = acc[sale.batch_uuid] || [];
          batch.push(sale);
          acc[sale.batch_uuid] = batch;
          return acc;
        }, {});

        // Calculate total revenue
        const revenue = salesData.reduce((sum, sale) => sum + sale.total_price, 0);
        setTotalRevenue(revenue);

        setSales(Object.entries(groupedSales)); // Convert object to array of batches
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    fetchSalesData();
  }, []);

  const getStatusCircle = (status) => (
    <span className={`status-circle ${status ? 'green' : 'red'}`} />
  );

  return (
    <div className="sales-container">
      <h2>Your Sales</h2>
      <p>Total Lifetime Revenue: ${totalRevenue.toFixed(2)}</p>
      <div className="sales-scrollable">
        {sales.length === 0 ? (
          <p>No sales found.</p>
        ) : (
          sales.map(([batchUuid, batchSales]) => {
            // Calculate total items and amount for the batch
            const totalItems = batchSales.reduce(
              (acc, sale) => acc + (sale.sire_quantity || sale.dam_quantity),
              0
            );
            const totalAmount = batchSales.reduce((acc, sale) => acc + sale.total_price, 0);

            // Assume all sales in a batch have the same status (for simplicity)
            const sellerPaid = batchSales.every((sale) => sale.seller_paid);
            const shipped = batchSales.every((sale) => sale.shipped);

            return (
              <div key={batchUuid} className="sales-batch">
                <h3>
                  Batch #{batchUuid} - {totalItems} item(s), Total: ${totalAmount.toFixed(2)}
                </h3>
                <p>Sold On: {new Date(batchSales[0]?.timestamp).toLocaleString()}</p>
                <ul>
                  {batchSales.map((sale, index) => {
                    const isSire = !!sale.sires?.name;
                    const productName = isSire ? sale.sires?.name : sale.dams?.name;
                    const productLink = `https://herdmate.app/product/${isSire ? sale.sire_id : sale.dam_id}`;
                    const buyerEmail = sale.users?.email;
                    const buyerPhone = sale.users?.phone_number;

                    return (
                      <li key={index}>
                        <a href={productLink} target="_blank" rel="noopener noreferrer">
                          {productName || 'Unnamed Product'}
                        </a>{' '}
                        - Quantity: {sale.sire_quantity || sale.dam_quantity}, Total: ${sale.total_price.toFixed(2)}
                        <br />
                        <strong>Buyer Info:</strong> {sale.buyer_address} | {buyerEmail} | {buyerPhone}
                      </li>
                    );
                  })}
                </ul>
                <div className="sales-status">
                  <p>
                    Paid: {getStatusCircle(sellerPaid)}
                  </p>
                  <p>
                    Shipped: {getStatusCircle(shipped)}
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default YourSales;
