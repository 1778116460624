import React from 'react';
import Header from './Header'; // Import your existing Header component
import './PaymentFailure.css'; // Import the new CSS file

export default function PaymentFailure() {
  return (
    <div>
      <Header /> {/* Reuse the existing Header */}
      <div className="payment-failure-container">
        <h1 className="payment-failure-title">Payment Failed</h1>
        <p className="payment-failure-description">
          Unfortunately, your payment could not be processed at this time.
        </p>
        <p className="payment-failure-description">
          Please try again or contact support for assistance.
        </p>
        <button
          onClick={() => window.location.href = '/cart'}
          className="payment-failure-button"
        >
          Go Back to Cart
        </button>
      </div>
    </div>
  );
}
