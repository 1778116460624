import React from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import Header from './Header'; // Adjust the import path to the location of your Header component
import './EmailConfirmPageForSignup.css'; // Import CSS for custom styling

export default function EmailConfirmPageForSignup() {
  const navigate = useNavigate(); // Hook for navigation

  return (
    <div>
      <Header />
      <div className="email-confirmation-message123">
        Signup successful! 
        <br />
        Please check your email to verify your account.
        <br />
        <button className="home-button123" onClick={() => navigate('/')}>
          Go to Home
        </button>
      </div>
    </div>
  );
}
