import React, { useEffect, useState } from 'react';
import supabase from './supabaseClient'; // Supabase client setup
import Header from './Header'; // Existing Header component


export default function SuccessPayment() {
  const [isFromStripe, setIsFromStripe] = useState(false);
  const [referrer, setReferrer] = useState('');

  useEffect(() => {
    // Try to get the referrer from `document.referrer`
    let lastVisited = document.referrer || localStorage.getItem('lastVisited') || 'Unknown';
    setReferrer(lastVisited);
    console.log(document.referrer)

    console.log(lastVisited)
    if (
      lastVisited?.startsWith('https://checkout.stripe.com/') || 
      lastVisited === 'cool' || 
      !lastVisited)
     {
      setIsFromStripe(true);
      localStorage.setItem('lastVisited', 'bruh'); // Prevent re-processing
      // Check if order processing has already been done
      const orderProcessed = localStorage.getItem('orderProcessed');
      if (orderProcessed === 'nahhhhh') {
        localStorage.setItem('orderProcessed', 'true'); // Prevent re-processing
        const checkoutData = JSON.parse(localStorage.getItem('checkoutData'));
        if (checkoutData) {
          handleOrderProcessing(checkoutData);
        }
      }
    }

    // Clear the `orderProcessed` flag when leaving the page
    return () => {
      localStorage.removeItem('orderProcessed');
    };
  }, []);

  async function fetchSellerInfo(itemId) {
    try {
      // First, check if the item is in the sires table
      const { data: sireData, error: sireError } = await supabase
        .from('sires')
        .select('user_uuid')
        .eq('sire_uuid', itemId)
        .single();
  
      if (sireData && !sireError) {
        // Fetch the user info for the sire's seller
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('user_uuid, email')
          .eq('user_uuid', sireData.user_uuid)
          .single();
  
        if (userData && !userError) {
          return {
            seller_uuid: userData.user_uuid,
            seller_email: userData.email,
          };
        }
      }
  
      // If not found in sires, check in dams table
      const { data: damData, error: damError } = await supabase
        .from('dams')
        .select('user_uuid')
        .eq('dam_uuid', itemId)
        .single();
  
      if (damData && !damError) {
        // Fetch the user info for the dam's seller
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('user_uuid, email')
          .eq('user_uuid', damData.user_uuid)
          .single();
  
        if (userData && !userError) {
          return {
            seller_uuid: userData.user_uuid,
            seller_email: userData.email,
          };
        }
      }
  
      console.error(`Seller info not found for itemId: ${itemId}`);
      return null;
    } catch (error) {
      console.error('Error fetching seller info:', error);
      return null;
    }
  }
  
  // Function to process the order and clear the cart
  const handleOrderProcessing = async (checkoutData) => {
    try {
      const { address, cartItems } = checkoutData;
      const user_uuid = localStorage.getItem('user_uuid');

      if (!cartItems || !user_uuid) {
        console.error('Cart items or user UUID missing');
        return;
      }

      const batch_uuid = generateBatchUUID();

      const doneOrders = [];
      let buyerEmail = '';


      for (let item of cartItems) {
        const isSire = await isSireItem(item.itemId);
        const order = {
          order_uuid: crypto.randomUUID(),
          user_uuid,
          sire_id: isSire ? item.itemId : null,
          dam_id: isSire ? null : item.itemId,
          sire_quantity: isSire ? item.quantity : 0,
          dam_quantity: isSire ? 0 : item.quantity,
          total_price: item.totalPrice,
          buyer_address: address,
          batch_uuid,
        };

        // Avoid duplicate entries by checking if the order already exists
        const query = supabase
        .from('done_orders')
        .select('order_uuid')
        .eq('user_uuid', user_uuid)
        .eq('sire_id', order.sire_id);
      
      if (order.dam_id === null) {
        query.is('dam_id', null); // Check for null value
      } else {
        query.eq('dam_id', order.dam_id);
      }
      
      const { data: existingOrder, error: fetchError } = await query.single();
      


                // Fetch buyer email for email notification
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('email')
          .eq('user_uuid', user_uuid)
          .single();

        if (userError) {
          console.error('Error fetching buyer email:', userError);
        } else {
          buyerEmail = userData.email;
        }


        doneOrders.push(order);
      }

      if (doneOrders.length > 0) {
        const { error: insertError } = await supabase.from('done_orders').insert(doneOrders);
        if (insertError) {
          console.error('Error adding to done_orders:', insertError);
        } else {
          console.log('Orders added successfully:', doneOrders);
      
          // Call the Flask server's API
          try {
            const response = await fetch('api.herdmate.app/send-order-confirmation-email', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                buyerEmail, // Email address of the buyer
                orderDetails: cartItems, // Array of items in the order
                batch_uuid,
                address
              }),
            });
      
            if (!response.ok) {
              const errorData = await response.json();
              console.error('Error sending order confirmation email:', errorData);
            } else {
              console.log('Order confirmation email sent successfully.');
            }
          } catch (error) {
            console.error('Error communicating with the email server:', error);
          }
        }
      }


      //Bro this is where the buyer email logic is
      //
      //
      //
      try {
        console.log('Preparing data to send to /send-seller-emails API...');
      
        // Enrich cart items with seller info
        const enrichedCartItems = await Promise.all(
          cartItems.map(async (item) => {
            // Fetch seller info
            const sellerData = await fetchSellerInfo(item.itemId);
            return {
              name: item.name,
              quantity: item.quantity,
              price: item.price, // Add price field here
              seller_uuid: sellerData?.seller_uuid || null,
              seller_email: sellerData?.seller_email || null,
            };
          })
        );
      
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('email')
          .eq('user_uuid', user_uuid)
          .single();
      
        if (userError) {
          console.error('Error fetching buyer email:', userError);
        } else {
          buyerEmail = userData.email;
        }
      
        console.log('Enriched cart items with seller info:', enrichedCartItems);
      
        const sellerResponse = await fetch('api.herdmate.app/send-sellers-emails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orderDetails: enrichedCartItems,
            batch_uuid,
            buyerAddress: address,
            buyerEmail,
          }),
        });
      
        if (!sellerResponse.ok) {
          const errorData = await sellerResponse.json();
          console.error('Error sending seller emails:', errorData);
        } else {
          console.log('Seller emails sent successfully.');
        }
      } catch (error) {
        console.error('Error communicating with the email server for seller emails:', error);
      }
            

      const { error: deleteError } = await supabase
        .from('cart_items')
        .delete()
        .eq('user_uuid', user_uuid);

      if (deleteError) {
        console.error('Error clearing cart_items:', deleteError);
      } else {
        console.log('Cart cleared successfully.');
      }
    } catch (err) {
      console.error('Unexpected error during order processing:', err);
    }
  };

  const generateBatchUUID = () => {
    return Math.floor(Math.random() * 10 ** 16).toString().padStart(16, '0');
  };
  
  const isSireItem = async (itemId) => {
    try {
      const { data: sireData, error: sireError } = await supabase
        .from('sires')
        .select('sire_uuid')
        .eq('sire_uuid', itemId)
        .single();

      return sireData && !sireError;
    } catch (err) {
      console.error('Error checking if item is a sire:', err);
      return false;
    }
  };

  if (!isFromStripe) {
    return (
      <div>
        <Header />
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h1>Access Denied</h1>
          <p>You cannot access this page directly.</p>
          <button
            onClick={() => {
              localStorage.removeItem('orderProcessed'); // Clear on explicit action
              window.location.href = '/';
            }}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
              backgroundColor: '#ff4d4d',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            Return to Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>Payment Successful!</h1>
        <p>Thank you for your order. Your payment has been processed successfully.</p>
        <button
          onClick={() => {
            localStorage.removeItem('orderProcessed'); // Clear on explicit action
            window.location.href = '/';
          }}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: '#4CAF50',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          Return to Home
        </button>
      </div>
    </div>
  );
}