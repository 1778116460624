import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import supabase from './supabaseClient';
import Header from './Header'; // Import the Header component
import './ReviewsPage.css';

const ReviewPage6 = () => {
  const { reviewee_id, reviewer_id } = useParams();
  const navigate = useNavigate();

  const [reviewText, setReviewText] = useState('');
  const [stars, setStars] = useState(5);
  const [reviewee, setReviewee] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [alreadyReviewed, setAlreadyReviewed] = useState(false);

  useEffect(() => {
    const fetchReviewDetails = async () => {
      try {
        const { data: revieweeData, error: revieweeError } = await supabase
          .from('users')
          .select('name, email, phone_number, address, city, state, zipcode, farm_name, farm_website')
          .eq('user_uuid', reviewee_id)
          .single();

        if (revieweeError) {
          console.error('Error fetching reviewee details:', revieweeError);
          return;
        }
        setReviewee(revieweeData);

        const { data: existingReview, error: reviewError } = await supabase
          .from('reviews')
          .select('*')
          .eq('reviewed_user_uuid', reviewee_id)
          .eq('reviewer_user_uuid', reviewer_id)
          .single();

        if (reviewError && reviewError.code !== 'PGRST116') {
          console.error('Error checking existing review:', reviewError);
          return;
        }

        if (existingReview) {
          setAlreadyReviewed(true);
        }
      } catch (error) {
        console.error('Error during review fetch:', error);
      }
    };

    fetchReviewDetails();
  }, [reviewee_id, reviewer_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!reviewText || !stars) {
      alert('Please fill out all fields.');
      return;
    }

    try {
      const { error } = await supabase.from('reviews').insert([
        {
          review_uuid: crypto.randomUUID(),
          reviewed_user_uuid: reviewee_id,
          review: reviewText,
          stars,
          reviewer_user_uuid: reviewer_id,
          verified: true,
        },
      ]);

      if (error) {
        console.error('Error submitting review:', error);
        alert('Failed to submit the review. Please try again later.');
        return;
      }

      setIsSubmitted(true);
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  if (isSubmitted) {
    navigate('/');
  }
  
  const userUUID = localStorage.getItem('user_uuid');

  if (!userUUID) {
    const confirmLogin = window.confirm('You need to log in first. Do you want to go to the login page?');
    if (confirmLogin) {
      navigate('/login'); // Redirect to the login page
    }
  } else if (reviewee_id !== userUUID) {
    navigate('/'); // Redirect to the home page or any other route
  }
  
  

  if (alreadyReviewed) {
    navigate('/');
  }

  return (
    <>
      <Header />
      <div className="review-page6">
        <h2>Leave a Review for {reviewee.name}</h2>
        <div className="reviewee-info">
        <p><strong>Farm Name:</strong> {reviewee.farm_name || 'N/A'}</p>
  <p>
    <strong>Farm Website:</strong>{' '}
    {reviewee.farm_website ? (
      <a href={reviewee.farm_website} target="_blank" rel="noopener noreferrer">
        {reviewee.farm_website}
      </a>
    ) : (
      'N/A'
    )}
  </p>
  <p>
    <strong>Contact:</strong> {reviewee.phone_number || 'N/A'}, {reviewee.email || 'N/A'}
  </p>

        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group6">
            <label htmlFor="stars">Rating (1-5):</label>
            <select
              id="stars"
              value={stars}
              onChange={(e) => setStars(Number(e.target.value))}
              required
            >
              {[1, 2, 3, 4, 5].map((star) => (
                <option key={star} value={star}>
                  {star} Star{star > 1 ? 's' : ''}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group6">
            <label htmlFor="review">Review:</label>
            <textarea
              id="review"
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              required
              placeholder="Write your experience..."
            />
          </div>

          <button type="submit">Submit Review</button>
        </form>
      </div>
    </>
  );
};

export default ReviewPage6;
