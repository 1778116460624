import React, { useState, useEffect } from 'react';
import './Saved.css';
import Header from './Header'; // Importing the existing header component
import supabase from './supabaseClient'; // Import your Supabase client

const SavedPage = () => {
  const userId = localStorage.getItem('user_uuid');
  const [savedItems, setSavedItems] = useState([]);

  // Fetch saved items from Supabase on component mount
  useEffect(() => {
    const fetchSavedItems = async () => {
      const { data, error } = await supabase
        .from('saved')
        .select('*, sires(*), dams(*)') // Fetch data with related 'sires' or 'dams'
        .eq('user_uuid', userId);

      if (error) {
        console.error("Error fetching saved items:", error);
      } else {
        // Map saved items to display relevant details
        const formattedItems = data.map(item => {
          const product = item.sires || item.dams;
          return {
            id: item.sire_uuid || item.dam_uuid,
            isSire: !!item.sire_uuid,
            name: product.name,
            breed: product.breed,
            location: `${product.city || 'Unknown'}, ${product.state || ''}`.trim(),
            price: `$${product.price}`,
            image: product.picture_aws_link1 || 'https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg'
          };
        });
        setSavedItems(formattedItems);
      }
    };

    fetchSavedItems();
  }, [userId]);

  // Remove item from saved list in Supabase
  const removeItem = async (id, index, isSire) => {
    const confirmRemoval = window.confirm("Are you sure you want to remove this item from your saved items?");
    if (!confirmRemoval) {
      return; // Exit if user cancels
    }

    const { error } = await supabase
      .from('saved')
      .delete()
      .eq('user_uuid', userId)
      .eq(isSire ? 'sire_uuid' : 'dam_uuid', id);

    if (error) {
      console.error("Error removing saved item:", error);
    } else {
      setSavedItems(savedItems.filter((_, i) => i !== index));
    }
  };

  // Handle navigation to product page in a new tab
  const navigateToProduct = (id) => {
    window.open(`/product/${id}`, '_blank');
  };

  return (
    <>
      <Header /> {/* Imported Header component */}
      <div className="saved-container">
        {savedItems.map((item, index) => (
          <div key={index} className="saved-item">
            <img 
              src={item.image} 
              alt={item.name} 
              onClick={() => navigateToProduct(item.id)} 
              style={{ cursor: 'pointer' }}
            />
            <button 
              className="remove-btn" 
              onClick={() => removeItem(item.id, index, item.isSire)}
            >
              X
            </button>
            <div className="item-details" onClick={() => navigateToProduct(item.id)}>
              <p><strong>{item.name}</strong></p>
              <p>{item.breed}</p>
              <p>{item.location}</p>
              <p>{item.price}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SavedPage;
