import React, { useState, useEffect } from 'react';
import Header from './Header';
import supabase from './supabaseClient';
import AWS from 'aws-sdk';
import { awsConfig } from './awsConfig';
import './Profile.css';

const Profile = () => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [farmName, setFarmName] = useState('');
  const [farmWebsite, setFarmWebsite] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const fileInputRef = React.createRef();

  const tempUUID = localStorage.getItem('user_uuid');

  useEffect(() => {
    const fetchUserData = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('name, email, phone_number, address, city, state, zipcode, image, farm_name, farm_website')
        .eq('user_uuid', tempUUID)
        .single();

      if (data) {
        setName(data.name);
        setEmail(data.email);
        setPhone(data.phone_number);
        setAddress(data.address);
        setCity(data.city);
        setState(data.state);
        setZipcode(data.zipcode);
        setImageUrl(data.image);
        setFarmName(data.farm_name);
        setFarmWebsite(data.farm_website);
      }

      if (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  AWS.config.update({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
    region: awsConfig.region,
  });

  const s3 = new AWS.S3();

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const uploadParams = {
        Bucket: awsConfig.bucketName,
        Key: `profile-images/${tempUUID}-${file.name}`,
        Body: file,
      };

      try {
        const uploadResult = await s3.upload(uploadParams).promise();
        setImageUrl(uploadResult.Location); 
        setProfilePicture(URL.createObjectURL(file));
        await handleSaveChanges({ imageOnly: true });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleSaveChanges = async ({ imageOnly = false } = {}) => {
    const updatedData = {
      image: imageUrl,
    };
    if (!imageOnly) {
      updatedData.name = name;
      updatedData.email = email;
      updatedData.phone_number = phone;
      updatedData.address = address;
      updatedData.city = city;
      updatedData.state = state;
      updatedData.zipcode = zipcode;
      updatedData.farm_name = farmName;
      updatedData.farm_website = farmWebsite;
    }

    const { error } = await supabase
      .from('users')
      .update(updatedData)
      .eq('user_uuid', tempUUID);

    if (error) {
      console.error('Error saving changes:', error);
    } 
  };

  return (
    <>
      <Header />

      <div className="profile-container">
        <div className="profile-header">
          <div className="profile-image-wrapper">
            <img
              src={profilePicture || imageUrl || 'https://placehold.co/600x400'}
              alt="Profile"
              className="profile-image"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <button onClick={triggerFileInput} className="upload-button">
              Upload Profile Picture
            </button>
          </div>
        </div>

        <div className="details-section">
          <div className="personal-details">
            <h3>Personal Details</h3>
            <form>
              <div className="form-group">
                <label>Name</label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="form-group">
                <label>Phone</label>
                <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
              </div>
            </form>
          </div>

          <div className="farm-details">
            <h3>Farm Details</h3>
            <form>
              <div className="form-group">
                <label>Farm Name</label>
                <input type="text" value={farmName} onChange={(e) => setFarmName(e.target.value)} />
              </div>
              <div className="form-group">
                <label>Farm Website</label>
                <input type="text" value={farmWebsite} onChange={(e) => setFarmWebsite(e.target.value)} />
              </div>
              <div className="form-group">
                <label>State</label>
                <input type="text" value={state} onChange={(e) => setState(e.target.value)} />
              </div>
              <div className="form-group">
                <label>City</label>
                <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
              </div>
              <div className="form-group">
                <label>Zipcode</label>
                <input type="text" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
              </div>
            </form>
          </div>
        </div>

        <button onClick={() => handleSaveChanges()} className="save-changes-button">
          Save Changes
        </button>
      </div>
    </>
  );
};

export default Profile;