import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaHeart, FaShoppingCart, FaShareAlt, FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon } from 'react-share';
import './ProductPageHeader.css';
import LoadingSpinner from './LoadingSpinner';  // Import the spinner component
import Header from './Header';
import supabase from './supabaseClient';

const ProductPageHeader = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const userId = localStorage.getItem('user_uuid');

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      setIsShareModalOpen(false);
    }
  };

  const shareUrl = `${window.location.origin}/product/${id}`;
  const shareTitle = `Check out this ${product?.name} (${product?.breed}) available!`;

  // Fetch product data and check if it's saved on initial load

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopySuccess('Link copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear success message after 2 seconds
    }).catch(err => {
      console.error("Failed to copy: ", err);
    });
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        let { data: sire, error: sireError } = await supabase
          .from('sires')
          .select('*')
          .eq('sire_uuid', id)
          .single();

        if (sireError && sireError.code === 'PGRST116') {
          const { data: dam, error: damError } = await supabase
            .from('dams')
            .select('*')
            .eq('dam_uuid', id)
            .single();

          if (damError) {
            console.error("Error fetching product data:", damError);
          } else {
            setProduct(dam);
            if (dam) checkIfSaved(dam.dam_uuid, false);
          }
        } else {
          setProduct(sire);
          if (sire) checkIfSaved(sire.sire_uuid, true);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    const checkIfSaved = async (productId, isSire) => {
      try {
        const { data, error } = await supabase
          .from('saved')
          .select('*')
          .eq('user_uuid', userId)
          .eq(isSire ? 'sire_uuid' : 'dam_uuid', productId);

        if (error) console.error("Error checking saved status:", error);
        setIsFavorite(data && data.length > 0);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleAddToCart = async () => {
    if (!product) return;
  
    const itemType = product.sire_uuid ? 'straws' : 'eggs';
    const totalPrice = (product.price * quantity).toFixed(2);
  
    const confirmAdd = window.confirm(
      `You want to add ${quantity} ${itemType} of ${product.name} for $${totalPrice} to your cart?`
    );
    if (!confirmAdd) return;
  
    try {
      const { data, error } = await supabase.from('cart_items').insert([
        {
          cart_item_uuid: crypto.randomUUID(),
          user_uuid: userId,
          sire_id: product.sire_uuid || null,  // Correctly sending sire_id
          dam_id: product.dam_uuid || null,    // Correctly sending dam_id
          sire_quantity: product.sire_uuid ? quantity : 0, 
          dam_quantity: product.dam_uuid ? quantity : 0, 
          total_price: product.price * quantity,
        }
      ]);
  
      if (error) {
        console.error('Error adding item to cart:', error);
        return;
      }
  
      console.log('Item added to cart:', data);
    } catch (error) {
      console.error("An error occurred while adding to cart:", error);
    }
  };
  

  const handleMessageSeller = async () => {
    if (!product || !product.user_uuid) return;
  
    const messageText = `Hi! I am interested in ${product.name}`;
  
    try {
      // Check if a chat already exists
      const { data: existingChat, error: checkError } = await supabase
        .from('messaging')
        .select('message_uuid')
        .or(`and(sender_uuid.eq.${userId},receiver_uuid.eq.${product.user_uuid}),and(sender_uuid.eq.${product.user_uuid},receiver_uuid.eq.${userId})`)
        .single();
  
      if (checkError && checkError.code !== 'PGRST116') {
        console.error('Error checking existing chat:', checkError);
        return;
      }
  
      if (!existingChat) {
        // No existing chat, create a new chat with the initial message
        const { error: insertError } = await supabase.from('messaging').insert([
          {
            message_uuid: crypto.randomUUID(),
            sender_uuid: userId,
            receiver_uuid: product.user_uuid,
            message: messageText,
            timestamp: new Date().toISOString(),
          },
        ]);
  
        if (insertError) {
          console.error('Error creating new chat:', insertError);
          return;
        }
      }
  
      // Navigate to the messaging page with the product owner's user UUID
      navigate(`/messages/${product.user_uuid}`, {
      });
    } catch (error) {
      console.error("An error occurred while setting up the chat:", error);
    }
  };
      
  const images = product
    ? [
        product.picture_aws_link1,
        product.picture_aws_link2,
        product.picture_aws_link3,
        product.picture_aws_link4,
        product.picture_aws_link5,
        product.picture_aws_link6,
        product.picture_aws_link7,
        product.picture_aws_link8,
      ].filter(Boolean)
    : [];

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToReviewsPage = (id) => {
    window.open(`/reviews/${product.user_uuid}`, '_blank');
  };


  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleFavoriteToggle = async () => {
    if (!product) return;

    try {
      if (isFavorite) {
        // Remove from saved items
        const { error } = await supabase
          .from('saved')
          .delete()
          .eq('user_uuid', userId)
          .eq(product.sire_uuid ? 'sire_uuid' : 'dam_uuid', product.sire_uuid || product.dam_uuid);

        if (error) {
          console.error('Error removing saved item:', error);
        } else {
          setIsFavorite(false);
        }
      } else {
        // Add to saved items
        const { error } = await supabase.from('saved').insert([
          {
            saved_uuid: crypto.randomUUID(),
            user_uuid: userId,
            sire_uuid: product.sire_uuid || null,
            dam_uuid: product.dam_uuid || null,
          },
        ]);

        if (error) {
          console.error('Error saving item:', error);
        } else {
          setIsFavorite(true);
        }
      }
    } catch (error) {
      console.error("An error occurred while saving or removing the item:", error);
    }
  };
  const handleShareClick = (e) => {
    e.stopPropagation();
    setIsShareModalOpen(true); // Open the share modal
  };


  if (!product) return <LoadingSpinner />;  // Show loading spinner while fetching data

  const isSire = product.sire_uuid !== undefined;

  return (
    <>
      <Header />

      <div className="product-page-container1">
        <div className="product-main-section1">
          <div className="product-image-section1">
            <img
              src={images[selectedImageIndex] || 'https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg'}
              alt="Product"
              className="product-image1"
            />

            <div className="image-controls1">
              <button
                className={`heart-button11 ${isFavorite ? 'active' : ''}`}
                onClick={handleFavoriteToggle}
              >
                <FaHeart color={isFavorite ? 'red' : 'white'} />
              </button>
            </div>

            <div className="rating-overlay1">
              <span className="rating-score1">Rating: {product.rating || 'N/A'}/5</span>
            </div>
          </div>

          <div className="product-info-section1">
            <h1>{product.name || "Unknown Name"}</h1>
            <p>BREED: {product.breed}</p>
            <p>DATE OF BIRTH: {product.birthdate || "Unknown"}</p>
            <p>PRICE: ${product.price}</p>
            <p>COLOR: {product.color || "N/A"}</p>

            <div className="cart-section1">
              <FaShoppingCart className="cart-icon1" />
              <label>Quantity:
                <select value={quantity} onChange={(e) => setQuantity(Number(e.target.value))}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((qty) => (
                    <option key={qty} value={qty}>{qty}</option>
                  ))}
                </select>
              </label>
              <button onClick={handleAddToCart} className="add-to-cart-button1">
                <FaShoppingCart /> Add to Cart
              </button>
            </div>

            <button className="message-seller-button1" onClick={handleMessageSeller}>MESSAGE SELLER</button>
            <button className="seller-info-button1" onClick={goToReviewsPage}>
          SELLER INFO + REVIEWS
        </button>
        <button className="share-button1" onClick={handleShareClick}><FaShareAlt /> Share</button>
        </div>
        </div>

        {isShareModalOpen && (
          <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
              <button className="close-modal-button" onClick={() => setIsShareModalOpen(false)}>
                &times;
              </button>
              <h3>Share this product</h3>
              <div className="share-buttons">
                <FacebookShareButton url={shareUrl} quote={shareTitle}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareTitle}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>

                <EmailShareButton url={shareUrl} subject={shareTitle} body="Check this out!">
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
              <button onClick={handleCopyLink} className="copy-link-button">
                Copy Link
              </button>
              {copySuccess && <span className="copy-success-message">{copySuccess}</span>}
            </div>
          </div>
        )}


        {/* Three sections for Main Details, Characteristics, Additional Information */}
        <div className="product-details-section1">
          <div className="details-box1">
            <h3>Main Details</h3>
            <p><strong>Registration Number:</strong> {product.breed_association_number || "N/A"}</p>
            <p><strong>Breed Association:</strong> {product.breed_association || "N/A"}</p>
            <p><strong>Website:</strong> <a href={product.breed_website} target="_blank" rel="noopener noreferrer">{product.breed_website}</a></p>
            <p><strong>Description:</strong> {product.description || "No description available."}</p>
          </div>

          <div className="details-box1">
            <h3>Characteristics</h3>
            {isSire ? (
              <>
                <p><strong>Calving Ease Direct:</strong> {product.calving_ease_direct}</p>
                <p><strong>Scrotal Circumference:</strong> {product.scrotal_circumference}</p>
                <p><strong>Birth Weight:</strong> {product.birth_weight}</p>
                <p><strong>Weaning Weight:</strong> {product.weaning_weight}</p>
                <p><strong>Yearling Weight:</strong> {product.yearling_weight}</p>
                <p><strong>Dry Matter Intake:</strong> {product.dry_matter_intake}</p>
                <p><strong>Docility:</strong> {product.docility}</p>
              </>
            ) : (
              <>
                <p><strong>Milk Yield:</strong> {product.milk_yield}</p>
                <p><strong>Calving Interval:</strong> {product.calving_interval}</p>
                <p><strong>Calving Ease DIR:</strong> {product.calving_ease_dir}</p>
                <p><strong>Calving Ease DTRS:</strong> {product.calving_ease_dtrs}</p>
                <p><strong>Gestation Length:</strong> {product.gestation_length}</p>
              </>
            )}
          </div>

          <div className="details-box1">
            <h3>Additional Information</h3>
            {isSire ? (
              <>
                <p><strong>Mature Weight:</strong> {product.mature_weight}</p>
                <p><strong>Mature Height:</strong> {product.mature_height}</p>
                <p><strong>Carcass Weight:</strong> {product.carcass_weight}</p>
                <p><strong>Carcass Marbling:</strong> {product.carcass_marbling}</p>
                <p><strong>Carcass Ribeye:</strong> {product.carcass_ribeye}</p>
                <p><strong>Carcass Fat:</strong> {product.carcass_fat}</p>
                <p><strong>F11:</strong> {product.f11}</p>
                <p><strong>F13:</strong> {product.f13}</p>
                <p><strong>CHS:</strong> {product.chs}</p>
                <p><strong>Band3:</strong> {product.band3}</p>
              </>
            ) : (
              <>
                <p><strong>Maternal Cow Weight:</strong> {product.maternal_cow_weight}</p>
                <p><strong>200 Day Milk:</strong> {product.day_200_milk}</p>
                <p><strong>Scrotal Size:</strong> {product.scrotal_size}</p>
                <p><strong>Carcase Weight:</strong> {product.carcase_weight}</p>
                <p><strong>Eye Muscle Area:</strong> {product.eye_muscle_area}</p>
                <p><strong>Rib Fat:</strong> {product.rib_fat}</p>
                <p><strong>Retail Beef Yield:</strong> {product.retail_beef_yield}</p>
                <p><strong>IMF:</strong> {product.imf}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPageHeader;
