import React, { useState, useEffect } from 'react';
import "./Header.css";
import logo from '../assets/HerdmateLogo.png'; 
import { useNavigate, Link } from 'react-router-dom';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in by checking localStorage
    const user = localStorage.getItem('user_uuid');
    setIsLoggedIn(!!user);
  }, []);

  const handleLoginLogout = () => {
    if (isLoggedIn) {
      localStorage.removeItem('user_uuid');
      setIsLoggedIn(false);
      navigate('/');
    } else {
      navigate('/login');
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-logo">
        <a href="/">
          <img src={logo} alt="Herdmate Logo" />
        </a>
        <Link to="/" className="header-title">
          <h1>Herdmate</h1>
        </Link>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
      </div>
      <nav className={`navbar ${menuOpen ? 'active' : ''}`}>
        <a href="/marketplace">Marketplace</a>
        <a href="/saved">Saved</a>
        <a href="/messages">Messages</a>
        <a href="/sell">Sell</a>
        <a href="/cart">Cart</a>
        <a href="/profile">Profile</a>
        <button className="login-button" onClick={handleLoginLogout}>
          {isLoggedIn ? 'Logout' : 'Login'}
        </button>
      </nav>
    </header>
  );
};

export default Header;
