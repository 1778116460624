import React, { useState } from 'react';
import './Forgot.css';
import Header from './Header';
import supabase from './supabaseClient';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSendResetLink = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'http://herdmate.app/reset-password'
      });
      
      
      

      if (error) {
        setError(`Error sending reset link: ${error.message}`);
      } else {
        setMessage('A password reset link has been sent to your email.');
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="forgot-password-page-container">
      <Header />
      <div className="forgot-password-form-container">
        <h2>Forgot Password</h2>
        <form onSubmit={handleSendResetLink} className="forgot-password-form">
          <label>Email</label>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="reset-button1">Send Reset Link</button>
        </form>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <div className="back-home">
          <a href="/">Back to Home</a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
