import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage'; 
import Marketplace from './components/Marketplace';
import Profile from './components/Profile';
import Saved from './components/Saved';
import Messages from './components/Messages';
import Sell from './components/SellPage'; 
import Cart from './components/Cart';
import Login from './components/Login';
import Forgot from './components/Forgot';
import SignUp from './components/Signup';
import Product1 from './components/ProductPageHeader';
import ReviewsPage from './components/Reviews';
import ResetPassword from './components/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import Success from './components/SuccessPayment'
import Failure from './components/PaymentFailure'
import TnC from './components/TnC'
import Team from './components/TeamPage'
import ReviewPage from './components/ReviewsPage'; // Adjust the path as necessary
import EmailConfirmPageForSignup from './components/EmailConfirmPageForSignup';

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/:reviewee_id/reviewed/:reviewer_id" element={<ReviewPage />}/>

        <Route path="/forgot" element={<Forgot />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/tnc" element={<TnC />} />
        <Route path="/team" element={<Team />} />
        <Route path="/signupemailconfirm" element={<EmailConfirmPageForSignup />} />



        <Route
          path="/success"
          element={
            <ProtectedRoute>
              <Success />
            </ProtectedRoute>
          }
        />

        <Route
          path="/canceled"
          element={
            <ProtectedRoute>
              <Failure />
            </ProtectedRoute>
          }
        />
        {/* Protected Routes */}
        <Route
          path="/marketplace"
          element={
            <ProtectedRoute>
              <Marketplace />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/saved"
          element={
            <ProtectedRoute>
              <Saved />
            </ProtectedRoute>
          }
        />
        <Route
          path="/messages"
          element={
            <ProtectedRoute>
              <Messages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/messages/:id"
          element={
            <ProtectedRoute>
              <Messages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sell"
          element={
            <ProtectedRoute>
              <Sell />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute>
          }
        />

        <Route
          path="/product/:id"
          element={
            <ProtectedRoute>
              <Product1 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reviews/:user_uuid"
          element={
            <ProtectedRoute>
              <ReviewsPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
